import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { getMediaUrl } from "../../Utils/firebase-helper";
import { Spinner } from 'reactstrap';

import BackArrow from '../../assets/img/popup_arrow_back.svg';

const PDFViewer = (props) => {
    const [modal, setModal] = useState(false);
    const [path, setPath] = useState('');

    const getPath = () => {
        getMediaUrl(props?.file?.filepath)
        .then((url) => {
          setPath(url);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const toggle = () => {
        setModal(!modal);
    };

    const onClose = () => {
        props.onClose();
        toggle();
    }

    useEffect(() => {
        setModal(props.isOpen);
        if (props?.file?.filepath) {
            getPath();
        }
    }, [props.isOpen])

    return(
        <Modal isOpen={modal} fade={false} toggle={toggle} className={'pdf-viewer'} centered backdrop='static'>
            <ModalHeader > 
                <img 
                    src={BackArrow}
                    className="back-arrow"
                    onClick={onClose}
                    alt="Back-Arraow" />
                <span className="header-text">{props?.file?.filename}</span>
            </ModalHeader>
            <ModalBody>
                <div className="loading-background"> 
                    {/* Loading... */}
                    <Spinner color="light" children={null} />
                </div>
                {/* <embed
                    src={path ? path : '' }
                    className="embed-div"/> */}
                {path && path !== ''
                ? <embed
                    src={path}
                    className="embed-div"/> : null}
            </ModalBody>
        </Modal>
    )
}

export default PDFViewer;