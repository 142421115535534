import React from "react";
import logo from "../../assets/img/logo.svg";
import styles from "./Logo.module.scss";


function Logo({title, subtitle}) {
  return (
    <>
      <div className={`${styles.logoContainer} ${title ? styles.withTitle : ""}`}>
        <img src={logo} alt="logo" />
      </div>
      {title && <p className={`${styles.title} ${!subtitle ? styles.withOutSubtitle : ""}`}>{title}</p>}
      {subtitle && <p className={styles.subTitle}>{subtitle}</p>}
    </>
  );
}

export default Logo;
