import i18next from "i18next";
import { connect } from 'react-redux';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import { ReactComponent as UserAvatar } from "../../assets/img/user_avatar.svg";

import * as api from '../../Utils/api-helper';
import { saveSelectedJobData } from "../../redux/actions/hiring-board.actions";
import { capitalizeText, showToast, showLoader } from '../../Utils/common-helper'
import ConfirmAlert from '../Confirm-Alert/index';
import SubHeader from '../Sub-Header/index';
import './job-details.scss';


const JobDetails = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [deleteJob, showDelete] = useState(false);
    const [jobData, setJobData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [jobId, setJobId] = useState(null);

    const locationDecider = location?.pathname == "/job-details";

    // useEffect(() => {
    //     setJobData(location?.state)
    // }, [locationDecider])

    const getDetails = (jobId) => {
        setIsLoading(true);
        api.getJobDetailsAPI(jobId).then(response => {
            setIsLoading(false);
            if (response && response.code && response.code === 200) {
                setJobData(response.data);
                props.saveSelectedJobData(response.data);
            } else {
                if(response && response.message){
                    showToast(response.message, "error");
                }
            }
        })
        .catch(error => {
            setIsLoading(false);
            if(error.data && error.data.message){
                showToast(error.data.message, "error");
            }
        })
    }

    const handleDelete = (e) => {
        stopEventBubbling(e);
        // location?.handleDelete(e, jobData?.state);
        showDelete(true);
    };
    
    const stopEventBubbling = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    };

    const navigateToUsersReported = () => {
        history.push({ pathname: "/user-reported", state: jobData?.state, crewRole: jobData?.crewRole, jobEmploymentStatus: jobData?.jobEmploymentStatus });
    }

    const onDeleteJob = () => {
        api.deleteJobAPI(jobData.id)
            .then(response => {
                if (response && response.data && response.code === 200) {
                    history.push('/hiring-board');
                    showToast(response.message, "success");
                }else{
                    showToast(response.message, "error");
                }
            })
            .catch(error => {
                if(error.data && error.data.message){
                    showToast(error.data.message, "error");
                }
            })
    }

    useEffect(() => {
        if(props.selectedJobData){
            // setJobData(props.selectedJobData);
            if(props.selectedJobData && props.selectedJobData.id){
                setJobId(props.selectedJobData.id)
            }
        }
    }, [props.selectedJobData]);

    useEffect(() => {
        if(jobId)
        getDetails(jobId)
    }, [jobId])

    const renderJobDetails = () => {
        return (
            <Card className="job-details-card">
                <div className="users-reported" onClick={() => navigateToUsersReported()}><UserAvatar /> 16 {i18next.t("USERS_REPORTED")}</div>
                <CardBody>
                    <CardTitle className="job-title">{jobData?.jobTitle}</CardTitle>
                    <CardSubtitle className="job-company-name">Airline aviation</CardSubtitle>
                    <div className="job-posted-date">{i18next.t("POSTED_ON")}: {moment(jobData?.jobCreatedAt).format('MMM DD, yyyy')}</div>

                    <div className="job-parameter-parent-container">
                        <div className="job-parameter-container">
                            <pre className="job-parameter">{i18next.t("CREW_POSITION")}: </pre>
                            <p className="job-parameter-value">{capitalizeText(jobData?.crewPosition)}</p>
                        </div>
                        <div className="job-parameter-container">
                            <pre className="job-parameter">{i18next.t("EMPLOYMENT_STATUS")}: </pre>
                            <p className="job-parameter-value">{ capitalizeText(jobData?.jobEmploymentStatus)}</p>
                        </div>

                        <div className="job-parameter-container">
                            <pre className="job-parameter">{i18next.t("BASE_CITY")}: </pre>
                            <p className="job-parameter-value">{jobData?.baseCity?.name}</p>
                        </div>
                        {jobData?.jobEmploymentStatus === 'Contracting' ?
                            <div className="job-parameter-container">
                                <pre className="job-parameter">{i18next.t("TRIP")}: </pre>
                                <p className="job-parameter-value">Trip Bali</p>
                                <Link className="trip-details-link">Details</Link>
                            </div> : null}
                        {jobData?.crewPosition === 'FLIGHT_ATTENDANT' || jobData?.crewPosition === 'CABIN_ATTENDANT' ?
                            <div className="job-parameter-container">
                                <pre className="job-parameter">{i18next.t("MIN_YEARS_OF_EXP")}: </pre>
                                <p className="job-parameter-value">{jobData?.minimumRequiredYearsOfExperience} yrs</p>
                            </div> : null}

                        {jobData?.crewPosition === 'CAPTAIN' || jobData?.crewPosition === 'FIRST_OFFICER' ?
                            <>
                                <div className="job-parameter-container">
                                    <pre className="job-parameter">{i18next.t("MIN_TOTAL_FLIGHT_HRS")}: </pre>
                                    <p className="job-parameter-value">{jobData?.minimumRequiredFlightHours} hrs</p>
                                </div>
                                <div className="job-parameter-container">
                                    <pre className="job-parameter">{i18next.t("AIRCRAFT_TYPE")}: </pre>
                                    <p className="job-parameter-value">{jobData?.aircraftType?.type}</p>
                                </div>
                                <div className="job-parameter-container">
                                    <pre className="job-parameter">{i18next.t("MIN_FLIGHT_TIME_IN_AIRCRAFT")}: </pre>
                                    <p className="job-parameter-value">{jobData?.minimumRequiredAircraftTypeFlightHours} hrs</p>
                                </div>
                            </>
                            : null}

                        <div className="job-parameter-container">
                            <pre className="job-parameter">{i18next.t("RELOCATION_EXP")}: </pre>
                            <p className="job-parameter-value">{jobData?.relocationExpensesPaid == true ? i18next.t('YES') : i18next.t('NO')}</p>
                        </div>
                    </div>
                    <div className="description">
                        <h6>{i18next.t("DUTIES_RESPONSIBILITIES")}</h6>
                        <CardText>{jobData?.description}</CardText>
                    </div>

                </CardBody>
            </Card>


        )
    }
    return (
        <div className="user-details-wrapper">
            {showLoader(isLoading)}
            <SubHeader
                title={i18next.t('JOB_DETAILS')}
                from="Job Details"
                isBack={true}
                isDelete={true}
                onDeleteUser={onDeleteJob}
                deleteConfrmMsg={i18next.t('DELETE_JOB_CONFIRMATION')}
            />
            <div className="common-wrapper">
                {renderJobDetails()}
            </div>


            {/* ALERTS */}
            <ConfirmAlert
                confirmMsg={i18next.t("DELETE_JOB_CONFIRMATION")}
                positiveBTN={i18next.t("DELETE_LABEL")}
                negativeBTN={i18next.t("CANCEL_LABEL")}
                isOpen={deleteJob}
                positiveCallback={(e) => {
                    showDelete(false);
                    handleDelete(e);
                }}
                negativeCallback={() => showDelete(false)}
            />
        </div>

    )
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedJobData: state.hiringBoard.selectedJobData
});

export default connect(mapStateToProps, {saveSelectedJobData})(JobDetails)
