import React from 'react';

import ResetPassword from "../../Components/ResetPassword";
import AppScreen from "../../Components/AppScreen";
import Logo from "../../Components/Logo";
import Card from "../../Components/Card";
import i18next from 'i18next';

function index(props) {
  return (
    <AppScreen>
    <Card>
      <Logo
        title={i18next.t('RESET_PASSWORD_T')}
      />
      <ResetPassword />
    </Card>
  </AppScreen>
  );
}

export default index;