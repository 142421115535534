import firebase from "firebase/app";
// These imports load individual services into the firebase namespace.
import "firebase/storage";
import 'firebase/auth';
import Emitter from "./Emitter";


let storage;
var firebaseConfig = {
  apiKey: "AIzaSyDIvdXzxxeChJIhIWInJTRjlligkuoUyK8",
  authDomain: "luxecrews-4b55c.firebaseapp.com",
  projectId: "luxecrews-4b55c",
  storageBucket: "luxecrews-4b55c.appspot.com",
  messagingSenderId: "11184207443",
  appId: "1:11184207443:web:ef26751a38e698a32ef8a6",
  measurementId: "G-Y1GLCJL5GJ",
};
/**
 * Function to intialize the firebase with the given config setting
 */
export const initializeApp = (token) => {
  if (firebase.apps.length > 0) {
    storage = firebase.storage();
  } else {
    // use environment-specific firebase config
    let firebaseApp = firebase.initializeApp(firebaseConfig);
    storage = firebase.storage();
  }
  getFirebaseToken(token);
};

/**
 * firebaseSigin - Function to sign in firebase using the custom firebase token
 */
export const getFirebaseToken = (token) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then(
        (result) => {
          resolve("success");
        },
        (error) => {
          if(error.code === 400){
            Emitter.emit("LOGOUT", {});
          }
          reject(error);
        }
      );
  });
};

/**
 * getMediaUrl
 */
export const getMediaUrl = (path) => {
  return new Promise((resolve, reject) => {
    storage
      .ref(`${path}`)
      .getDownloadURL()
      .then((url) => {
        resolve(url);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
