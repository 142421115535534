import { toast } from "react-toastify";
import LoadingSpinner from '../Components/Loading-Spinner';
import ConfirmAlert from '../Components/Confirm-Alert';

export function showToast(message, type) {
  if (type) {
    if (type === "info") {
      toast.info(message);
    } else if (type === "success") {
      toast.success(message);
    } else if (type === "warn") {
      toast.warn(message);
    } else if (type === "error") {
      toast.error(message);
    } else if (type === "dark") {
      toast.dark(message);
    }
  } else {
    toast(message);
  }
}

export function showLoader(spinnerFlag) {
  return (
    spinnerFlag && spinnerFlag === true ? <LoadingSpinner /> : null
  )
}

export function confirmAlert(message, positiveBTN, negativeBTN, isOpen, customClass, positiveCallback, negativeCallback,) {
  return <ConfirmAlert
    confirmMsg={message}
    positiveBTN={positiveBTN}
    negativeBTN={negativeBTN}
    isOpen={isOpen}
    customClass={customClass}
    positiveCallback={() => positiveCallback()}
    negativeCallback={() => negativeCallback()} />
}

export function capitalizeText(text) {
  let str = text ? text : '';
  str = str.replace('_', ' ');
  str = str.toLowerCase();
  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

  }
  const finalSTR = arr.join(" ");
  return finalSTR;
}

export function saveAsJson(fileName, fileContent) {
  writeContents(fileContent, fileName + '.json', 'application/json')

}

export function writeContents(content, fileName, contentType) {
  var a = document.createElement('a');
  var file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}

export function saveAsZip(fileName, fileContent) {
  var a = document.createElement('a');
  const blob = new Blob([fileContent], {
    type: 'application/zip'
  });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName + '.zip';
  a.click();
}

export const truncateString = (fullStr, strLen, separator) => {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || '...';

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars);
};
export function goClone(source) {
  if (Object.prototype.toString.call(source) === "[object Array]") {
    let clone = [];
    for (let i = 0; i < source.length; i++) {
      clone[i] = goClone(source[i]);
    }
    return clone;
  } else if (typeof source == "object") {
    let clone = {};
    for (let prop in source) {
      if (source.hasOwnProperty(prop) && source[prop]) {
        clone[prop] = goClone(source[prop]);
      } else {
        clone[prop] = source[prop];
      }
    }
    return clone;
  } else {
    return source;
  }
}

export function phoneNumberFormatter(string) {
  return string.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
}