import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { confirmAlert, showLoader,showToast } from "../../Utils/common-helper"
import { getTripList, deleteTrip } from "../../Utils/api-helper";
import { saveSelectedTripId } from "../../redux/actions/trip-management.action";

import sortIcon from "../../assets/img/sort.svg";
import deleteIcon from "../../assets/img/delete.svg";
import NoDataFoundIcon from "../../assets/img/no_search_found_icon.svg";
import NoTripFoundIcon from "../../assets/img/no_trip_found.svg"

import SubHeader from '../Sub-Header';
import './trip-management.scss';

function TripManagement(props) {
    const history = useHistory();
    const [deleteModal, setDeleteModal] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [tripList, setTripList] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [preSearchText, setPreSearchText] = useState('');
    const [selectedDeleteID, setSelectedDeleteID] = useState('');
    const [params, setParams] = useState({
        searchText: "",
        sort: "CREATED_AT",
        order: "DESC",
        page: 0,
        size: 20,
    });

    const handleSortClick = () => {
        if (tripList.length === 0) {
            return;
        }
        const tempParams = { ...params };
        tempParams.order = tempParams.order === "ASC" ? "DESC" : "ASC";
         tempParams.page = 0;
        setParams(tempParams);
        setHasMore(true);
    };

    const goToTripDetails = (trip) => {
        props.saveSelectedTripId(trip.id);
        history.push({ pathname: "/trip-details", state: { data: {} } });
    };

    const handleDeleteClick = (e, trip) => {
        stopEventbubbling(e);
        setDeleteModal(true);
        setSelectedDeleteID(trip.id)
    };

    const onCancelDelete = () => {
        setDeleteModal(false);
    }

    const onConfirmDelete = () => {
        setDeleteModal(false);
        deleteTrip(selectedDeleteID)
            .then((response) => {
                showToast(response.message, "success");
                const tempParams = { ...params };
                tempParams.page = 0;
                setParams(tempParams);
            })
            .catch((error) => {
                if (error.data && error.data.message) {
                    showToast(error.data.message, "error");
                }
            });
    }

    const onScrollTripList = () => {
        const tempParams = { ...params };
        tempParams.page++;
        if (totalPages > tempParams.page) {
        setParams(tempParams);
        } else {
        setHasMore(false);
        }
    };

    const stopEventbubbling = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    };

    const getTrips = (onScroll) => {
        setIsLoading(true);
        getTripList(params)
            .then((response) => {
                if (response && response.data) {
                    if (response.data.list && response.data.list.length > 0) {

                        let allTripList = [];
                        if (onScroll) {
                            allTripList = tripList.concat(response.data.list);
                        } else {
                            allTripList = [];
                            allTripList = response.data.list;
                        }
                        setTotalPages(response.data.totalPages);
                        if (response.data.list.length < params.size) {
                            setHasMore(false);
                        } else {
                            setHasMore(true);
                        }
                        setTripList(allTripList);
                        if (params.page === 0) {
                            let x = document.getElementById('tbody');
                            x.scrollTop = 0;
                        }
                    } else {
                        setTripList([]);
                    }
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                // props.saveParamData([]);
                setIsLoading(false);
                if (error.data && error.data.message) {
                    showToast(error.data.message, "error");
                }
            });
    }

    useEffect(() => {
        getTrips(params.page === 0 ? false : true);
    }, [params])

    return(
        <div className="page-container trip-management-container">
            <SubHeader
                title={i18next.t('TRIP_MANAGMENT_LABEL')}
                isBack={false}
                isSearchBar={true}
                params={params}
                setParams={setParams}
                from={'Trip Management'}
                 />
            <div className="table-conatiner">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th className="name-col width-15" scope="col">
                                {i18next.t("TRIP_NAME")}
                            </th>

                            <th className="width-15" scope="col">
                                <div className="checkbox-container-rem header-checkbox">
                                    <div className="sort-container">
                                        <span>{i18next.t("START_DATE")}</span>
                                        {/* <img
                                            className="sort-icon"
                                            alt="dropdown"
                                            src={sortIcon}
                                            onClick={() => handleSortClick()}
                                        /> */}
                                    </div>
                                </div>
                            </th>

                            <th className="width-15" scope="col">
                                <div className="checkbox-container-rem header-checkbox">
                                    <div className="sort-container">
                                        <span>{i18next.t("END_DATE")}</span>
                                        {/* <img
                                            className="sort-icon"
                                            alt="dropdown"
                                            src={sortIcon}
                                            onClick={() => handleSortClick()}
                                        /> */}
                                    </div>
                                </div>
                            </th>

                            <th className="width-225" scope="col">
                                {i18next.t("AIRCRAFT_TAIL_NUMBER")}
                            </th>

                            <th className="width-10" scope="col">
                                {i18next.t("FLIGHT_LEGS")}
                            </th>

                            <th className="width-15" scope="col">
                                <div className="checkbox-container-rem header-checkbox">
                                    <div className="sort-container">
                                        <span>{i18next.t("CREATED_BY")}</span>
                                        {/* <img
                                            className="sort-icon"
                                            alt="dropdown"
                                            src={sortIcon}
                                            onClick={() => handleSortClick()}
                                        /> */}
                                    </div>
                                </div>
                            </th>

                            <th className="width-15" scope="col">
                                <div className="checkbox-container-rem header-checkbox">
                                    <div className="sort-container">
                                        <span>{i18next.t("CREATED_ON")}</span>
                                        <img
                                            className="sort-icon"
                                            alt="dropdown"
                                            src={sortIcon}
                                            onClick={() => handleSortClick()}
                                        />
                                    </div>
                                </div>
                            </th>

                            <th className="width-10" scope="col">

                            </th>
                        </tr>
                    </thead>
                    <tbody id="tbody">
                        <InfiniteScroll
                            dataLength={tripList.length} //This is important field to render the next data
                            next={onScrollTripList}
                            hasMore={hasMore}
                            // loader={<h4>Loading...</h4>}
                            scrollableTarget="tbody"
                        >
                            {tripList.map((trip, index) => {
                                return(
                                    <tr className="row-hover" key={`listTrip000${index}`} onClick={() => goToTripDetails(trip)}>
                                        <td className="width-15 name-col">
                                            <span>{trip?.tripName}</span>
                                            {/* <div className="checkbox-container-rem  body-checkbox">
                                                <div className="user-detail-container">
                                                    <span>{trip?.tripName}</span>
                                                </div>
                                            </div> */}
                                        </td>
                                        <td className="width-15">
                                            {moment(trip?.startDateTime).format('ll')}
                                        </td>

                                        <td className="width-15 role">
                                            {moment(trip?.endDateTime).format('ll')}
                                        </td>
                                        <td className="width-225">
                                            {trip?.aircraftTailNumber}
                                        </td>

                                        <td className="width-10">
                                            {trip && trip.flightLeg ? trip.flightLeg : '-'}
                                        </td>

                                        <td className="width-15">
                                            {trip?.tripCreatedBy}
                                        </td>

                                        <td className="width-15">
                                            {moment(trip?.tripCreatedAt).format('ll')}
                                        </td>

                                        <td className="width-10">
                                            <div className="genderBtn">
                                                <div className="hover-button-container">
                                                    <div className="hover-button-section">
                                                        <img
                                                            onClick={(e) => handleDeleteClick(e, trip)}
                                                            title="Delete"
                                                            alt="Delete"
                                                            src={deleteIcon}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            
                            
                        </InfiniteScroll>
                    </tbody>

                    {tripList && tripList.length === 0 && !isLoading && (
                        <div className="text-center no-data">
                            <img src={params.searchText.length > 0 ? NoDataFoundIcon : NoTripFoundIcon} alt="no active user" />
                            <h6 className="no-data-title pt-3">
                                {i18next.t("NO_DATA_FOUND")}
                            </h6>
                    </div>
                    )}
                </table>
                {showLoader(isLoading)}
                {confirmAlert(
                    i18next.t('DELETE_TRIP_CONFIRMATION'),
                    i18next.t('DELETE_LABEL'),
                    i18next.t('CANCEL_LABEL'),
                    deleteModal,
                    'danger-btn',
                    () => onConfirmDelete(),
                    () => onCancelDelete()
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    tripId: state.tripManagement.selectedTripId
});

export default connect(mapStateToProps, {saveSelectedTripId})(TripManagement);