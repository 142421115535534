import * as Yup from 'yup';
import i18next from 'i18next';

export const loginValidation = () => {
  return {
    initialValues : {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(i18next.t('INVALID_EMAIL')).required(i18next.t('EMAIL_REQUIRED')),
      password: Yup.string().required(i18next.t('PASSWORD_REQUIRED'))
      // .min(6,i18next.t('INVALID_PASSWORD'))
      // .matches(/^(?=.*[A-Za-z])(?=.*?[0-9]).{6,}$/,i18next.t('INVALID_PASSWORD')),
    })
  }

};
export const forgotPasswordValidation = () => {
  return {
    initialValues : {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(i18next.t('INVALID_EMAIL')).required(i18next.t('REGISTERED_EMAIL_REQUIRED')),
    })
  }  
}

export const resetPasswordValidation = () => {
  return {
    initialValues : {
      password: '',
      confirmPassword:''
    },
    validationSchema: Yup.object({
      password: Yup.string().required(i18next.t('NEW_PASSWORD_REQUIRED'))
      .min(6,i18next.t('INVALID_PASSWORD'))
      .matches(/^(?=.*[A-Za-z])(?=.*?[0-9]).{6,}$/,i18next.t('INVALID_PASSWORD')),
      confirmPassword: Yup.string().required(i18next.t('CONFIRM_PASSWORD_REQUIRED'))
      .test('passwords-match', i18next.t('PASSWORD_MUST_MATCH'), function(value){
        return this.parent.password === value
      })
    })
  }

};



export const addSubscriptionValidation = () => {
  return {
    initialValues : {
      plan_name: '',
      // password: '',
    },
    validationSchema: Yup.object({
      plan_name: Yup.string().required(i18next.t('sdfsdf')),
      // password: Yup.string().required(i18next.t('PASSWORD_REQUIRED'))
      // .min(6,i18next.t('INVALID_PASSWORD'))
      // .matches(/^(?=.*[A-Za-z])(?=.*?[0-9]).{6,}$/,i18next.t('INVALID_PASSWORD')),
    })
  }
}



