import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import i18next from 'i18next';
import moment from 'moment';
import './masterData.management.scss';
import { saveAsJson, showToast, showLoader, truncateString } from '../../Utils/common-helper';
import { downloadFile, updateFile, getAllEntityUpdateInfo } from '../../Utils/api-helper';

import SubHeader from '../Sub-Header';
import DownloadIcon from '../../assets/img/download_icon.svg';
import CheckMarkIcon from '../../assets/img/check_mark.svg';
import UncheckIcon from '../../assets/img/uncheck.svg'
import ModalCrossWhite from '../../assets/img/modal_cross_white.svg';
import ChipCrossIcon from '../../assets/img/chip_cross.svg';
// import NoDataFoundIcon from "../../assets/img/no_data_found.svg"
import NoDataFoundIcon from "../../assets/img/no_search_found_icon.svg";



function MasterDataManagement(props) {
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [allEntityList, SetAllEntityList] = useState([]);
    const [allEntityListBackup, SetAllEntityListBackup] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const inputFileRef = useRef( null );
    const [params, setParams] = useState({
        searchText: "",
    });


    const onFileChange = ( e, entity ) => {
        if(e.target.files && e.target.files.length){
            if(e.target.files[0].type === 'application/json'){
                setSelectedFile(e.target.files[0]);
                setModal(true);
            }else{
                showToast(i18next.t('VALID_FILE_FORMAT_ERROR'), "error");
            }
        }
    }

    const handleBtnClick = (entity) => {
        inputFileRef.current.click();
        setSelectedEntity(entity);
    }

    
    const toggle = () => {
        if(modal){
            inputFileRef.current.value = '';
        }
        setModal(!modal);
    };

    const downloadDataFile = (entity) => {
        downloadFile(entity.masterDataTable)
            .then((response) => {
                if (response) {
                     let data = JSON.stringify(response);
                    saveAsJson(entity.masterDataTableDisplayName, data);
                }     
            })
            .catch((error) => {
                if (error.data && error.data.message) {
                    showToast(error.data.message, "error");
                    
                }
        });
    }

    const submitUpdatedData = () => {
        setIsLoading(true)
        let formData = new FormData();
        formData.append('file', selectedFile , selectedFile.name);
        updateFile(formData, selectedEntity.masterDataTable)
                .then((response) => {
                       setIsLoading(false);
                    if(response.code === 200){   
                        showToast(response.message, "success");
                        getAllEntityList();
                        toggle();
                    }else{
                        showToast(response.message, "error");
                    }
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.data && error.data.message) {
                    showToast(error.data.message, "error");
                }
            });
        
    }

    const getAllEntityList = () => {
        setIsLoading(true);
        getAllEntityUpdateInfo()
            .then((response) => {
                setIsLoading(false);
                if (response.data) {
                    SetAllEntityList(response.data)
                    SetAllEntityListBackup(response.data);
                    setIsDataFetched(true);
                }
            })
            .catch((error) => {
                setIsDataFetched(true);
                setIsLoading(false);
                if (error.data && error.data.message) {
                    showToast(error.data.message, "error");
                }
            });
    }

    useEffect(() => {
        getAllEntityList();
    }, [])

    useEffect(() => {
        if(params && params.searchText && params.searchText.length){
            let allEntities = [];
            allEntities = [...allEntityListBackup]
            let filteredEntity = allEntities.filter((e) => {
                return e.masterDataTableDisplayName.toLowerCase().includes(params.searchText.toLowerCase());
            });
            SetAllEntityList(filteredEntity);
        }else if(params.searchText.length === 0){
            let tempArray = [];
            tempArray = [...allEntityListBackup];
            SetAllEntityList(tempArray);
        }
    }, [params])


    const renderUpdateModal = () => {
        return(
            <Modal
                isOpen={modal}
                toggle={toggle}
                className={'entity-update-modal'}
                centered>
                <ModalBody>
                    <>
                        <div className="heading-wrapper">
                            <div className="heading-text">{`${i18next.t('UPDATE_LABEL')} ${selectedEntity?.masterDataTableDisplayName}`}</div>
                            <div className="close-div">
                                <img
                                    src={ModalCrossWhite}
                                    onClick={toggle}
                                    alt="close-icon" />
                            </div>
                        </div>
                        <div className="details-wrapper">
                            <div className="entity-file-details">
                                <div className="data-file-text-wrapper">{i18next.t('ENTITY_DATA_FILE_TEXT')}</div>
                                <div className="data-file-chip-wrapper">
                                    <div className="data-chip">
                                        <div className="file-name" title={selectedFile?.name}>
                                            {/* {selectedFile?.name} */}
                                            {selectedFile?.name && selectedFile?.name.length > 20 ? truncateString(selectedFile?.name, 20, '...'): selectedFile?.name}
                                            </div>
                                        <div className="chip-cross">
                                            <img src={ChipCrossIcon} alt='cross' onClick={toggle} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-btn-wrapper">
                            <button 
                                    type="button"
                                    onClick={() => submitUpdatedData()}
                                    className={`solid-btn ripple-light-ms-update`}>
                                    {i18next.t('UPDATE_LABEL')}
                                </button>
                            </div>
                        </div>
                    </>
                </ModalBody>
            </Modal>
        )
    }

    return(
        <div className="masterdata-wrapper">
            {showLoader(isLoading)}
            {renderUpdateModal()}
            <div className="masterdata-sub-wrapper entity-list-section">
                 <SubHeader
                    title={i18next.t('MASTER_DATA_LABEL')} 
                    isBack={false}
                    isSearchBar={true}
                    params={params}
                    setParams={setParams} />
                
                {allEntityList.length > 0
                ?   <div className="note-wrapper">
                        <span className="note-text">{i18next.t('MASTER_DATA_NOTE')}</span>
                    </div>
                : null}

                {allEntityList.length > 0 
                ? <div className="entity-list-wrapper">
                    {
                        allEntityList.map((entity, index) => {
                            return(
                                <div className="entity-card" key={`entitycard000${entity.masterDataTable}`}>
                                    <div className="entity-card-header">
                                        <div
                                            className="entity-name"
                                            title={entity?.masterDataTableDisplayName}>
                                            {entity?.masterDataTableDisplayName}
                                        </div>
                                        <div
                                            className="download-icon"
                                            title="Click to dowload file">
                                            <img src={DownloadIcon} alt='download-icon' onClick={() => downloadDataFile(entity)} />
                                        </div>
                                    </div>
                                    <div className="entity-card-body">
                                        <div className="updated-info">
                                            {entity?.lastUpdatedOn 
                                            ?   <img src={CheckMarkIcon} alt="check-mark-icon" className="check-icon" />
                                            :   <img src={UncheckIcon} alt="uncheck-icon" className="uncheck-icon" />}
                                            {entity && entity.lastUpdatedOn
                                            ? <span className="info-text">{`${i18next.t('LAST_UPDATED_ON_TEXT')}: ${entity && entity.lastUpdatedOn ? moment(entity.lastUpdatedOn).format('MMM DD, yyyy') : ''}`}</span>
                                            : <span className="info-text">{`${i18next.t('NOT_UPDATED_YET_TEXT')}`}</span>}
                                        </div>
                                        <div className="update-btn-wrapper">
                                            <button
                                                type="button"
                                                onClick={() => handleBtnClick(entity)}
                                                className={`outline-btn ripple-light`}>
                                                {i18next.t('UPDATE_LABEL')}
                                            </button>
                                            
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    
                    <input
                        ref={inputFileRef}
                        type="file"
                        accept=".json"
                        style={{ display: 'none' }}
                        onChange={(e) => onFileChange(e)}
                    />
                </div> : null }
                {allEntityList.length === 0 && isDataFetched
                ?   <div className="no-data-wrapper text-center">
                        <div>
                            <img src={NoDataFoundIcon} alt="no-data-found-icon" />
                            <h6 className="no-data-title pt-3">
                                    {i18next.t('NO_DATA_FOUND')}{" "}
                            </h6>
                        </div>
                    </div>
                :   null}
            </div>
        </div>
    );
}
export default MasterDataManagement;