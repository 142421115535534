import React from 'react';


import Login from "../../Components/Login";
import AppScreen from "../../Components/AppScreen";
import Logo from "../../Components/Logo";
import Card from "../../Components/Card";

function index(props) {
  return (
    <AppScreen>
      <Card>
        <Logo />
        <Login />
      </Card>
    </AppScreen>
  );
}

export default index;