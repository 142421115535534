import React, { useState, useEffect } from 'react';
import "./user-details.scss";
import i18next from 'i18next';
import { useLocation, useHistory } from "react-router-dom";
import moment from 'moment';
import { capitalizeText, showToast, showLoader } from '../../Utils/common-helper';

import FBIcon from '../../assets/img/fb_icon.svg';
import LinkedInIcon from '../../assets/img/linkedin_icon.svg';
import InstaIcon from '../../assets/img/instagram_icon.svg';
import TwitterIcon from '../../assets/img/twitter_icon.svg';

import SubHeader from '../Sub-Header/index';
import InfoCard from '../Info-Card/index';
import FirebaseImage from "../FirebaseImage";

import * as api from '../../Utils/api-helper';
import { date } from 'yup/lib/locale';



function UserDetails(props) {
    const location = useLocation();
    const history = useHistory();

    const [userDetails, setUserDetails] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [approvedType, setApprovedType] = useState(null);

    const onBlockUser = () => {
        let payload = [];
        payload.push(userId)
        api.blockUsers(payload)
            .then(response => {
                if (response && response.data && response.code === 200) {
                    // getDetails(location.state.data.userId);
                    getDetails(localStorage.getItem('userID'))
                }
            })
            .catch(error => {
                if(error.data && error.data.message){
                    showToast(error.data.message, "error");
                }
            })
    }
    
    const onUnblockUser = () => {
        let payload = [];
        payload.push(userId)
        api.unBlockUsers(payload)
            .then(response => {
                if (response && response.data && response.code === 200) {
                    // getDetails(location.state.data.userId);
                    getDetails(localStorage.getItem('userID'))
                }
            })
            .catch(error => {
                if(error.data && error.data.message){
                    showToast(error.data.message, "error");
                }
            })
    }

    const onDeleteUser = () => {
        let payload = [];
        payload.push(userId)
        api.deleteUsers(payload)
            .then(response => {
                if (response && response.data && response.code === 200) {
                    history.push('/user-management');
                }
            })
            .catch(error => {
                if(error.data && error.data.message){
                    showToast(error.data.message, "error");
                }
            })
    }

    const onApproveUser = () => {
        api.approveUser(userId)
            .then(response => {
                if (response && response.data && response.code === 200) {
                    //  getDetails(location.state.data.userId);
                    getDetails(localStorage.getItem('userID'))
                }
            })
            .catch(error => {
                if(error.data && error.data.message){
                    showToast(error.data.message, "error");
                }
            })
    }

    const onRejectUser = () => {
        api.rejectUser(userId)
            .then(response => {
                if (response && response.data && response.code === 200) {
                    //getDetails(location.state.data.userId);
                    getDetails(localStorage.getItem('userID'))
                }
            })
            .catch(error => {
                if(error.data && error.data.message){
                    showToast(error.data.message, "error");
                }
            })
    }

    const getDetails = (userId) => {
        setIsLoading(true);
        api.getUserDetails(userId)
            .then(response => {
                setIsLoading(false);
                if (response && response.data && response.code === 200) {
                    if (response.data.generalProfileDTO){
                        setUserDetails(response.data.generalProfileDTO);
                    }else if (response.data.flightAttendantProfileDTO){
                        setUserDetails(response.data.flightAttendantProfileDTO);
                    }else if (response.data.pilotProfileDTO){
                        setUserDetails(response.data.pilotProfileDTO);
                    }
                    setApprovedType(response?.data?.approvedType);
                    setUserId(response?.data?.userId)
                }
            })
            .catch(error => {
                setIsLoading(false);
                if(error.data && error.data.message){
                    showToast(error.data.message, "error");
                }
            })
    }

    const getFinalURL = (url) => {
        if(url){
            if (url.startsWith("https://")) {
                return url;
            } else {
                return 'https://' + url;
            }
        }
        
    }

    const getuserStatus = () => {
        let user = userDetails
        let finalStatus = '';
        if(user?.isBlocked && user?.isBlocked === true) {
            finalStatus = 'Blocked'
        } else if(user?.isBlocked && user?.isBlocked === false && user.reportedByDetails && user.reportedByDetails.length) {
          finalStatus = 'Reported';
        } else {
            if(approvedType)
          finalStatus = approvedType;
        }
        return capitalizeText(finalStatus);
    }

    useEffect(() => {
        if(location?.state?.data?.userId){
            getDetails(location?.state?.data?.userId);
        }else {
            getDetails(localStorage.getItem('userID'));
        }
    }, []);

    return(
        <div className="user-details-wrapper">
            {showLoader(isLoading)}
            <SubHeader 
                title={i18next.t('USER_DETAILS')} 
                from="User Details"
                isBack={true}
                onBlockUser={onBlockUser}
                onUnblockUser={onUnblockUser}
                onDeleteUser={onDeleteUser}
                onApproveUser={onApproveUser}
                onRejectUser={onRejectUser}
                deleteConfrmMsg={i18next.t('DELETE_USER_CONFIRMATION')}
                isApproved={approvedType === "APPROVED" ? true : approvedType === "PENDING" ? false : null}
                isBlocked={userDetails?.isBlocked} />

            <div className="common-wrapper">
                <div className="personal-info-wrapper">
                    <div className="title">{i18next.t('PERSONAL_INFO_LABEL')}</div>
                    <div className="info-container">
                        <div className="profile-pic-section">
                            <div className="profile-pict">
                                <FirebaseImage className="no-margin" src={userDetails?.profileImagePath} from="user-details" />
                            </div>
                            <div 
                                className={`user-status ${getuserStatus() === 'Pending' ? 'pending-text' : getuserStatus() === 'Approved' ? 'approve-text' : getuserStatus() === 'Rejected' ? 'rejected-text' : getuserStatus() === 'Blocked' ? 'blocked-text' : getuserStatus() === 'Reported' ? 'reported-text' : ''}`}>
                                {getuserStatus()}
                            </div>
                        </div>
                        <div className="profile-info-section">
                            <div className="personal-info">
                                <div className="row-div">
                                    <div className="key-text">{i18next.t('NAME')}:</div>
                                    <div className="value-text">{`${userDetails?.firstName ? userDetails?.firstName : ''} ${userDetails?.lastName ? userDetails?.lastName : ''}`}</div>
                                </div>

                                <div className="row-div">
                                    <div className="key-text">{i18next.t('ROLE')}:</div>
                                    <div className="value-text">{capitalizeText(userDetails?.role)}</div>
                                </div>

                                <div className="row-div">
                                    <div className="key-text">{i18next.t('PHONE_LABEL')}:</div>
                                    <div className="value-text">{`${userDetails?.countryCode ? userDetails?.countryCode : ''} ${userDetails?.contactNo ? userDetails?.contactNo : ''}`} </div>
                                </div>

                                <div className="row-div">
                                    <div className="key-text">{i18next.t('DOB_LABEL')}:</div>
                                    <div className="value-text">
                                        {userDetails?.dob ? moment(userDetails?.dob).format('MMM DD, yyyy') : null}
                                    </div>
                                </div>

                                <div className="row-div">
                                    <div className="key-text">{i18next.t('GENDER')}:</div>
                                    <div className="value-text">{capitalizeText(userDetails?.gender)}</div>
                                </div>

                                <div className="row-div">
                                    <div className="key-text">{i18next.t('EMAIL_ID')}:</div>
                                    <div className="value-text">{userDetails?.email}</div>
                                </div>

                                {userDetails?.baseLocation 
                                ?   <>   <div className="row-div">
                                            <div className="key-text">{i18next.t('BASE_CITY_LABEL')}:</div>
                                            <div className="value-text">{userDetails?.baseLocation?.cityName}</div>
                                        </div>

                                        <div className="row-div">
                                            <div className="key-text">{i18next.t('BASE_STATE_LABEL')}:</div>
                                            <div className="value-text">{userDetails?.baseLocation?.stateName}</div>
                                        </div>
                                    </>
                                :   null}

                                {userDetails?.socialMedia && userDetails?.socialMedia.length
                                ?  <div className="social-section">
                                        {userDetails.socialMedia.map((media, index) => {
                                            return(
                                                <div className="social-item" key={`media00${index}`}>
                                                    <a href={getFinalURL(media?.profileLink)} target="_blank">
                                                        <img src={media?.socialMediaType === 'FACEBOOK' 
                                                                    ? FBIcon 
                                                                    : media?.socialMediaType === 'LINKEDIN' 
                                                                    ? LinkedInIcon 
                                                                    : media?.socialMediaType === 'INSTAGRAM' 
                                                                    ? InstaIcon 
                                                                    : media?.socialMediaType === 'TWITTER'
                                                                    ? TwitterIcon
                                                                    : null} 
                                                        alt={`${media?.socialMediaType}-ICON`} />
                                                    </a>
                                                </div>
                                            );
                                        })}
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>

                {userDetails?.educationalQualificationList && userDetails?.educationalQualificationList?.length 
                ?   <InfoCard title={i18next.t('AIRCARFT_QUALIFICATION_LABEL')} viewType="list" eduData={userDetails?.educationalQualificationList} />
                :   null}

                {userDetails?.resumeTitle && userDetails?.resumeDescription && userDetails?.resumePdfPath 
                ?   <InfoCard title={i18next.t('RESUME_LABEL')} viewType="details" data={[{title: userDetails.resumeTitle, description: userDetails.resumeDescription, pdfName: userDetails.resumePdfName, pdfSize: userDetails.resumePdfSize, pdfPath: userDetails.resumePdfPath}]} />
                :   null}

                {userDetails?.experiences && userDetails.totalFlightHours
                ?   <InfoCard title={i18next.t('EXPERIENCE_LABEL')} viewType="experienceView" experienceData={[{experiences: userDetails.experiences, totalFlightHours: userDetails.totalFlightHours}]} /> 
                :   userDetails?.experiences && userDetails.experienceYear
                ?   <InfoCard title={i18next.t('EXPERIENCE_LABEL')} viewType="experienceView" experienceData={[{experiences: userDetails.experiences, experienceYear: userDetails.experienceYear, experienceMonth: userDetails.experienceMonth}]} />
                :   null}
                

                {userDetails?.logbookList && userDetails?.logbookList?.length 
                ?   <InfoCard title={i18next.t('LOGBOOK_LABEL')} viewType="details" data={userDetails?.logbookList} />
                : null}

                {userDetails?.degreeList && userDetails?.degreeList?.length 
                ?   <InfoCard title={i18next.t('DEGREE_CERTIFI_LABEL')} viewType="degreeView" degreeData={userDetails.degreeList} />
                :   null}
                
                {userDetails?.aircraftDTOList && userDetails?.aircraftDTOList?.length
                ?   <InfoCard title={i18next.t('AIRCRAFT_INFO_LABEL')} viewType="aircraftInfoview" aircraftInfoData={userDetails.aircraftDTOList} companyName={userDetails.companyName} />
                :   null }

                {userDetails?.aboutMe 
                ?   <InfoCard title={i18next.t('ABOUT_ME_LABEL')} viewType="about" aboutMeText={userDetails?.aboutMe} /> 
                :   null}
            </div>   
        </div>
    );
}

export default UserDetails;