import React from 'react';

import MasterDataManagement from '../../Components/MasterData-Management';

function index(props) {
  
  return (
    <MasterDataManagement />
  );
}

export default index;