import React, { useState } from "react";
import i18next from "i18next";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {TextField} from "./TextField";
import { loginValidation } from "../Utils/ValidationService";
import AppForm from "./AppForm";
import { adminLogin } from "../Utils/api-helper";
import { showToast } from "../Utils/common-helper";
import { saveAuthData, saveUserData } from "../redux/actions/auth.actions";

function Login(props) {
  const history = useHistory();
  let { initialValues, validationSchema } = loginValidation();
  // Check if user has enabled remember me ..if yes then load save credentials
  const isRememberMeChecked = props.rememberMeData ? true : false;
  const [checked, setChecked] = useState(isRememberMeChecked);
  if (isRememberMeChecked) {
    initialValues = props.rememberMeData;
  }

  const handleSubmit = (values) => {
    const payload = {
      emailId: values.email,
      oauth2ClientId: "",
      password: values.password,
    };
    adminLogin(payload)
      .then((response) => {
        if (response && response.data && response.code === 200) {
          // showToast(response.message,'success');
          // if remember me is checked then save creds
          if (checked) {
            props.saveUserData({
              email: values.email,
              password: values.password,
            });
          } else {
            props.saveUserData(undefined);
          }
          props.saveAuthData(response.data);
          let path = "/user-management";
          history.replace(path);
        } else{
          showToast(response.message, "error");
        }
      })
      .catch((error) => {
        if(error.data && error.data.message){
          showToast(error.data.message, "error");
        }
      });
  };

  const onCheckValid = (data) => {
  }

  return (
    <AppForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onCheckValid={onCheckValid}
    >
      <TextField label={i18next.t("EMAIL_LABEL")} type="text" name="email" />
      <TextField
        label={i18next.t("PASSWORD")}
        type="password"
        name="password"
      />
      <div className="checkbox-container-rem mb-3 login-checkbox">
        <div>
        {/* <label className="checkbox"> */}
          <input
            type="checkbox"
            name="checkbox"
            style={{marginRight: 10 ,}}
            defaultChecked={checked}
            onChange={() => setChecked(!checked)}
            id="checkbox"
          />
          <span className={checked ? 'checked-remember' : 'unchecked-remember'}>{i18next.t("REMEMBER_ME")}</span>
        {/* </label> */}
        </div>
      <div>
        <Link to="/forgot-password" className="btn btn-link p-0 forgot-text">
          {i18next.t("FORGOT_PASSWORD")}
        </Link>
        </div>
      </div>
      <div className="d-grid gap-2">
        <button className={`btn btn-primary ripple loginBtn`} type="submit">
          {i18next.t("LOGIN")}
        </button>
      </div>
    </AppForm>
  );
}

const mapStateToProps = (state) => ({
  rememberMeData: state.auth.rememberMeData,
});

export default connect(mapStateToProps, { saveAuthData, saveUserData })(Login);
