import React from 'react';

import HiringBoard from '../../Components/Hiring-Board';

function index(props) {
  
  return (
    <HiringBoard />
  );
}

export default index;