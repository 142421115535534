import i18next from "i18next";
import moment from 'moment';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';

import * as api from '../../Utils/api-helper';
import ConfirmAlert from '../Confirm-Alert/index';
import { capitalizeText, showToast } from '../../Utils/common-helper'
import { saveSelectedJobData } from "../../redux/actions/hiring-board.actions";
import SubHeader from '../Sub-Header/index';
import './user-reported.scss';

const UserReported = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [jobData, setJobData] = useState();
    const [deleteJob, showDelete] = useState(false);


    const handleDelete = () => {
        api.deleteJobAPI(jobData.id)
            .then(response => {
                if (response && response.data && response.code === 200) {
                    history.push('/hiring-board');
                    showToast(response.message, "success");
                }else{
                    showToast(response.message, "error");
                }
            })
            .catch(error => {
                if(error.data && error.data.message){
                    showToast(error.data.message, "error");
                }
            })
    };
    const stopEventBubbling = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    };
    // useEffect(() => {
    //     setJobData(jobData.state)
    // }, [location])

    useEffect(() => {
        if (props.selectedJobData) {
            setJobData(props.selectedJobData);
        }
    }, [props.selectedJobData]);

    const renderJobDetails = () => {
        return (
            <>
                <Card className="">
                    <CardBody>
                        <div className="delete-btn" title={i18next.t('DELETE_LABEL')}>
                            <button
                                type="button"
                                className="btns ripple-light"
                                onClick={() => showDelete(true)}
                            >
                                {i18next.t('DELETE_LABEL')}
                            </button>
                        </div>
                        <CardTitle className="job-title">{jobData?.jobTitle}</CardTitle>
                        <CardSubtitle className="job-company-name">Airline aviation</CardSubtitle>
                        <div className="job-posted-date">{i18next.t("POSTED_ON")}: {moment(jobData?.jobCreatedAt).format('MMM DD, yyyy')}</div>

                        <div className="job-parameter-parent-container">
                            <div className="job-parameter-container">
                                <pre className="job-parameter">{i18next.t("CREW_POSITION")}: </pre>
                                <p className="job-parameter-value">{capitalizeText(jobData?.crewPosition)}</p>
                            </div>
                            <div className="job-parameter-container">
                                <pre className="job-parameter">{i18next.t("EMPLOYMENT_STATUS")}: </pre>
                                <p className="job-parameter-value">{capitalizeText(jobData?.jobEmploymentStatus)}</p>
                            </div>

                            <div className="job-parameter-container">
                                <pre className="job-parameter">{i18next.t("BASE_CITY")}: </pre>
                                <p className="job-parameter-value">{jobData?.baseCity?.name}</p>
                            </div>
                            {jobData?.jobEmploymentStatus === 'Contracting' ?
                                <div className="job-parameter-container">
                                    <pre className="job-parameter">{i18next.t("TRIP")}: </pre>
                                    <p className="job-parameter-value">Trip Bali</p>
                                    <Link className="trip-details-link">Details</Link>
                                </div> : null}
                            {jobData?.crewPosition === 'FLIGHT_ATTENDANT' || jobData?.crewPosition === 'CABIN_ATTENDANT' ?
                                <div className="job-parameter-container">
                                    <pre className="job-parameter">{i18next.t("MIN_YEARS_OF_EXP")}: </pre>
                                    <p className="job-parameter-value">{jobData?.minimumRequiredYearsOfExperience} yrs</p>
                                </div> : null}

                            {jobData?.crewPosition === 'CAPTAIN' || jobData?.crewPosition === 'FIRST_OFFICER' ?
                                <>
                                    <div className="job-parameter-container">
                                        <pre className="job-parameter">{i18next.t("MIN_TOTAL_FLIGHT_HRS")}: </pre>
                                        <p className="job-parameter-value">{jobData?.minimumRequiredFlightHours} hrs</p>
                                    </div>
                                    <div className="job-parameter-container">
                                        <pre className="job-parameter">{i18next.t("AIRCRAFT_TYPE")}: </pre>
                                        <p className="job-parameter-value">{jobData?.aircraftType?.type}</p>
                                    </div>
                                    <div className="job-parameter-container">
                                        <pre className="job-parameter">{i18next.t("MIN_FLIGHT_TIME_IN_AIRCRAFT")}: </pre>
                                        <p className="job-parameter-value">{jobData?.minimumRequiredAircraftTypeFlightHours} hrs</p>
                                    </div>
                                </>
                                : null}

                            <div className="job-parameter-container">
                                <pre className="job-parameter">{i18next.t("RELOCATION_EXP")}: </pre>
                                <p className="job-parameter-value">{jobData?.relocationExpensesPaid == true ? i18next.t('YES') : i18next.t('NO')}</p>
                            </div>
                        </div>
                        <div className="description">
                            <h6>{i18next.t("DUTIES_RESPONSIBILITIES")}</h6>
                            <CardText>{jobData?.description}</CardText>
                        </div>
                    </CardBody>
                </Card>
            </>
        )
    }
    const renderJobComments = () => {
        return (
            <div className="comment-card">
                <div>
                    <img className="comment-img" src='https://picsum.photos/200' />
                </div>
                <div>
                    <div className='comment-title'>
                        <div style={{display:'flex',alignItems: 'baseline'}}>
                            <h5>Ava Joyce</h5>
                            <h6>Flight Attendant</h6>
                        </div>
                        <h6>Jul 20, 2021, 3:00 PM</h6>
                    </div>
                    <div className='comment-description'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</div>

                </div>
            </div>
        )
    }
    return (
        <div className="user-reported-container">
            <SubHeader
                title={i18next.t('USERS_REPORTED')}
                from="Users Reported"
                isBack={true}
                deleteConfrmMsg={i18next.t('DELETE_JOB_CONFIRMATION')}
            />
            <div className="common-wrapper">
                {renderJobDetails()}
                <div className="comment-tab">{i18next.t('COMMENTS')}</div>
                {renderJobComments()}
                {renderJobComments()}
                {renderJobComments()}


            </div>
            {/* ALERTS */}
            <ConfirmAlert
                confirmMsg={i18next.t("DELETE_JOB_CONFIRMATION")}
                positiveBTN={i18next.t("DELETE_LABEL")}
                negativeBTN={i18next.t("CANCEL_LABEL")}
                isOpen={deleteJob}
                positiveCallback={() => {
                    showDelete(false);
                    handleDelete();
                }}
                negativeCallback={() => showDelete(false)}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedJobData: state.hiringBoard.selectedJobData
});

export default connect(mapStateToProps, { saveSelectedJobData })(UserReported);
