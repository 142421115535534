import i18next from "i18next";
import moment from 'moment';
import { connect } from 'react-redux';
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardText, CardTitle, List } from 'reactstrap';
import InfiniteScroll from "react-infinite-scroll-component";

import clearSearch from "../../assets/img/black-cross.svg";
import { ReactComponent as Delete } from "../../assets/img/delete.svg";
import dropDownArrow from "../../assets/img/dropdown-arrow.svg";
import NoDataFoundIcon from "../../assets/img/no_search_found_icon.svg";
import searchIcon from "../../assets/img/search-icon.svg";

import { deleteJobAPI, getJobListAPI } from "../../Utils/api-helper";
import { goClone, showLoader, showToast } from "../../Utils/common-helper";
import { saveSelectedJobData, saveHiringBoardParamData } from "../../redux/actions/hiring-board.actions";
import ConfirmAlert from '../Confirm-Alert/index';
import "./hiring-board.scss";


let jobEmploymentStatusOptions = [
    { id: "ALL", value: "All" },
    { id: "FULL_TIME", value: "Full-Time" },
    { id: "CONTRACT", value: "Contract" },
];
let crewList = [
    { id: "ALL", value: "All" },
    { id: "CAPTAIN", value: "Captain" },
    { id: "FIRST_OFFICER", value: "First Officer" },
    { id: "FLIGHT_ATTENDANT", value: "Flight Attendant" },
    { id: "CABIN_ATTENDANT", value: "Cabin Attendant" },
];
function HiringBoard(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [jobList, setJobList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [deleteJob, showDelete] = useState(false);
    const [deleteJobId, setDeleteJobId] = useState('');
    const [deleteJobIndex, setDeleteJobIndex] = useState('');
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const history = useHistory();
    let [preSearchText, setPreSearchText] = useState('');
    let [params, setParams] = useState({
        page: 0,
        size: 20,
        searchText: "",
        filter: "ALL",
        role: "ALL",
        sort: "NAME",
        order: "ASC",
        selectedFilter: { id: "ALL", value: "All" },
        selectedRole: { id: "ALL", value: "All" },
    });
    let arrayHolder = goClone(jobList);

    // ******************Header filter function*****************
    const handleFilterClick = (selectedOption, type) => {
        const tempParams = { ...params };
        if (type === "role") {
            tempParams.role = selectedOption?.id;
            tempParams.selectedRole = selectedOption;
        } else {
            tempParams.filter = selectedOption.id;
            tempParams.selectedFilter = selectedOption;
        }
        tempParams.page = 0;
        setParams(tempParams);
    };


    const handleClearSearch = () => {
        const tempParams = { ...params };
        tempParams.searchText = "";
        setParams(tempParams);
        let temp = { ...preSearchText };
        temp = '';
        setPreSearchText(temp);
    };

    const handleSearchChange = (text) => {
        if (preSearchText != text) {
            setPreSearchText(text);
        } else {

        }
    };

    const submitSearch = (e) => {
        if (e.charCode == 13) {
            // const newData = arrayHolder.filter(item => {
            //     const itemData = item?.jobTitle?.toUpperCase();
            //     const textData = preSearchText.toUpperCase();
            //     return itemData.indexOf(textData) > -1;
            // });
            // setJobList(newData)

            const tempParams = { ...params };
            tempParams.page = 0;
            tempParams.searchText = preSearchText;
            setParams(tempParams);
        }
    }


    const stopEventBubbling = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    };

    const handleDeleteJob = (e, item, index) => {
        stopEventBubbling(e);
        setDeleteJobId(item?.id);
        setDeleteJobIndex(index);
        showDelete(true);
    };

    // *********API CALLS**************
    useEffect(() => {
        if(props.paramData && props.paramData.length){
            const tempParams = { ...params };
            tempParams.filter = props.paramData[0].filters;
            tempParams.selectedRole = props.paramData[0].selectedRole;
            tempParams.selectedFilter = props.paramData[0].selectedFilter;
            tempParams.role = props.paramData[0].role ;
            tempParams.page = 0;
            props.saveHiringBoardParamData([]);
            setParams(tempParams);
        }else{
            getJobList(params.page === 0 ? false : true);
        }
    }, [params]);

    const getJobList = (onScroll) => {
        setIsLoading(true);
        let body = {
            crewPosition: params?.selectedRole?.id,
            employmentStatus: params?.selectedFilter?.id,
            page: params.page,
            size: params.size,
            searchText: params.searchText
        }
        getJobListAPI(body).then(res => {
            setIsLoading(false);
            setIsDataFetched(true);
            if (res.data.list && res.data.list.length > 0) {
                let allJobList = [];
                if (onScroll) {
                    allJobList = jobList.concat(res.data.list);
                } else {
                    allJobList = [];
                    allJobList = res.data.list;
                }
                setTotalPages(res?.data?.totalPages);
                if (res.data.list.length < params.size) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }

                setJobList(allJobList);
                if (params.page === 0) {
                    let x = document.getElementById('containerScroll');
                    x.scrollTop = 0;
                }
            }else{
                setJobList([]);
            }
        }).catch((error) => {
            setIsDataFetched(true);
            setIsLoading(false);
            if (error.data && error.data.message) {
                showToast(error.data.message, "error");
            }
        });
    }
    const handleDelete = () => {
        setIsLoading(true);
        deleteJobAPI(deleteJobId).then(deleteJob => {
            setIsLoading(false);
            showToast(deleteJob.message, "success");
            const tempParams = { ...params };
            tempParams.page = 0;
            setParams(tempParams);
            //jobList.splice(deleteJobIndex, 1);
            setDeleteJobId('')
            setDeleteJobIndex('')
        }).catch((error) => {
            setIsDataFetched(true);
            setIsLoading(false);
            if (error.data && error.data.message) {
                showToast(error.data.message, "error");
            }
        });
    }

    const onScrollJobList = () => {
        const tempParams = { ...params };
        tempParams.page++;
        if (totalPages > tempParams.page) {
        setParams(tempParams);
        } else {
        setHasMore(false);
        }
    };


    // ***********************NAVIGATION***********************
    const navigateToJobDetails = (item, crewRole, jobEmploymentStatus) => {
        props.saveSelectedJobData(item);
        let obj = {
            selectedFilter: params.selectedFilter,
            filters: params.filter,
            selectedRole: params.selectedRole,
            role: params.role
        }
        let arry = [];
        arry.push(obj);
        props.saveHiringBoardParamData(arry);
        history.push({ pathname: "/job-details", state: { state: item, crewRole: crewRole, jobEmploymentStatus: jobEmploymentStatus, handleDelete } });

    }

    // ***********************MISC FUNCTIONS***********************
    const valueProviderForLists = (pos, list) => {
        let label = list.filter(i => i.id == pos)[0];
        return label;
    }

    const renderEmptyView = () => {
        return (
        jobList.length === 0 && !isLoading
        ?   <div className="no-hiring-data-wrapper text-center">
                <div>
                    <img src={NoDataFoundIcon} alt="no-data-found-icon" />
                    <h6 className="no-data-title pt-3">
                        {i18next.t('NO_DATA_FOUND')}{" "}
                    </h6>
                </div>
            </div>
        : null)
    }

    const renderJobList = () => {

        return (
            <List type="unstyled" className="job-list" >
                <InfiniteScroll
                            dataLength={jobList.length} //This is important field to render the next data
                            next={onScrollJobList}
                            hasMore={hasMore}
                            // loader={<h4>Loading...</h4>}
                            scrollableTarget="containerScroll"
                >
                    {jobList?.length && isDataFetched ?
                    (jobList.map((item, index) => {
                        let crewRole = valueProviderForLists(item.crewPosition, crewList).value;
                        let jobEmploymentStatus = valueProviderForLists(item.jobEmploymentStatus, jobEmploymentStatusOptions).value;

                        return (
                            <li key={item.id + index}>
                                <Card className="job-list-container" onClick={() => navigateToJobDetails(item, crewRole, jobEmploymentStatus)}>
                                    <CardBody>
                                        <Delete className="job-delete-icon" onClick={(e) => handleDeleteJob(e, item, index)} />
                                        <CardTitle className="job-title">{item?.jobTitle}</CardTitle>
                                        <CardSubtitle className="job-company-name">Airline aviation</CardSubtitle>
                                        <div className="job-posted-date">{i18next.t("POSTED_ON")}: {moment(item?.jobCreatedAt).format('MMM DD, yyyy')}</div>

                                        <div className="job-parameter-parent-container">
                                            <div className="job-parameter-container">
                                                <pre className="job-parameter">{i18next.t("CREW_POSITION")}: </pre>
                                                <p className="job-parameter-value">{crewRole}</p>
                                            </div>
                                            <div className="job-parameter-container">
                                                <pre className="job-parameter">{i18next.t("EMPLOYMENT_STATUS")}: </pre>
                                                <p className="job-parameter-value">{jobEmploymentStatus}</p>
                                            </div>

                                            <div className="job-parameter-container">
                                                <pre className="job-parameter">{i18next.t("BASE_CITY")}: </pre>
                                                <p className="job-parameter-value">{item?.baseCity?.name}</p>
                                            </div>
                                            {item?.jobEmploymentStatus === 'Contracting' ?
                                                <div className="job-parameter-container">
                                                    <pre className="job-parameter">{i18next.t("TRIP")}: </pre>
                                                    <p className="job-parameter-value">Trip Bali</p>
                                                    <Link className="trip-details-link">Details</Link>
                                                </div> : null}
                                            {item?.crewPosition === 'FLIGHT_ATTENDANT' || item?.crewPosition === 'CABIN_ATTENDANT' ?
                                                <div className="job-parameter-container">
                                                    <pre className="job-parameter">{i18next.t("MIN_YEARS_OF_EXP")}: </pre>
                                                    <p className="job-parameter-value">{item?.minimumRequiredYearsOfExperience} yrs</p>
                                                </div> : null}

                                            {item?.crewPosition === 'CAPTAIN' || item?.crewPosition === 'FIRST_OFFICER' ?
                                                <>
                                                    <div className="job-parameter-container">
                                                        <pre className="job-parameter">{i18next.t("MIN_TOTAL_FLIGHT_HRS")}: </pre>
                                                        <p className="job-parameter-value">{item?.minimumRequiredFlightHours} hrs</p>
                                                    </div>
                                                    <div className="job-parameter-container">
                                                        <pre className="job-parameter">{i18next.t("AIRCRAFT_TYPE")}: </pre>
                                                        <p className="job-parameter-value">{item?.aircraftType?.type}</p>
                                                    </div>
                                                    <div className="job-parameter-container">
                                                        <pre className="job-parameter">{i18next.t("MIN_FLIGHT_TIME_IN_AIRCRAFT")}: </pre>
                                                        <p className="job-parameter-value">{item?.minimumRequiredAircraftTypeFlightHours} hrs</p>
                                                    </div>
                                                </>
                                                : null}

                                            <div className="job-parameter-container">
                                                <pre className="job-parameter">{i18next.t("RELOCATION_EXP")}: </pre>
                                                <p className="job-parameter-value">{item.relocationExpensesPaid == true ? i18next.t('YES') : i18next.t('NO')}</p>
                                            </div>
                                        </div>
                                        <CardText>{item?.description}</CardText>
                                    </CardBody>
                                </Card>
                            </li>)
                    })) : null}
                </InfiniteScroll>
            </List>)
    }

    return (
        <div className="page-container hiring-board-container">
            <div className="sub-header-wrapper">
                <div className="title-wrapper">
                    <span>{i18next.t("HIRING_BOARD_LABEL")}</span>
                </div>

                <div className="action-wrapper">
                    <div className="dropdown ">
                        <span className="dropdown-label">{i18next.t("ROLE_LABEL")}</span>
                        <button
                            className="btn  dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <div
                                className="selected-dd-text"
                                style={{ width: '120px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                                title={params.selectedRole?.value}>{params.selectedRole?.value}</div>
                            <img
                                className="dropDownArrow"
                                alt="dropdown"
                                src={dropDownArrow}
                            />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            {crewList.map((pos) => (
                                <li
                                    key={pos.id}
                                    onClick={() => handleFilterClick(pos, "role")}
                                >
                                    <button className="dropdown-item" type="button">
                                        {pos?.value}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="dropdown ">
                        <span className="dropdown-label">{i18next.t("TYPE")}</span>
                        <button
                            className="btn  dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {params.selectedFilter?.value}
                            <img
                                className="dropDownArrow"
                                alt="dropdown"
                                src={dropDownArrow}
                            />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            {jobEmploymentStatusOptions.map((filter) => (
                                <li
                                    key={filter.id}
                                    onClick={() => handleFilterClick(filter, "filter")}
                                >
                                    <button className="dropdown-item" type="button">
                                        {filter.value}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <span className="search-container search-form">
                        <span className="ipad-fixes">{' '}</span>
                        <img className="search-icon search-icon-user" alt="search" src={searchIcon} />
                        <input
                            type="text"
                            name="search"
                            id="search"
                            value={preSearchText}
                            onChange={(event) => handleSearchChange(event.target.value)}
                            onKeyPress={(e) => submitSearch(e)}
                            placeholder="Search"
                            className="form-control search-background"
                        />
                        {preSearchText && preSearchText.length > 0 ? (
                            <img
                                className="clear-search clear-search-user"
                                onClick={() => handleClearSearch()}
                                alt="cross"
                                src={clearSearch}
                            />
                        ) : (
                            ""
                        )}
                    </span>
                </div>
            </div>
            <div className="common-wrapper" id="containerScroll">
                {renderJobList()}
                {renderEmptyView()}
            </div>
            {/* ************ Alerts ************ */}
            <ConfirmAlert
                confirmMsg={i18next.t("DELETE_JOB_CONFIRMATION")}
                positiveBTN={i18next.t("DELETE_LABEL")}
                negativeBTN={i18next.t("CANCEL_LABEL")}
                isOpen={deleteJob}
                customClass={'danger-btn'}
                positiveCallback={() => {
                    showDelete(false);
                    handleDelete();
                }}
                negativeCallback={() => showDelete(false)}
            />

            {/* ************ LOADER ************ */}
            {showLoader(isLoading)}
        </div>
    )
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    jobData: state.hiringBoard.selectedJobData,
    paramData: state.hiringBoard.paramData
});

export default connect(mapStateToProps, {saveSelectedJobData, saveHiringBoardParamData})(HiringBoard)
