import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { saveParamData } from "../../redux/actions/user-management.action"
import "./user-management.scss";
import i18next from "i18next";
import dropDownArrow from "../../assets/img/dropdown-arrow.svg";
import sort from "../../assets/img/sort.svg";
import clearSearch from "../../assets/img/black-cross.svg";
import searchIcon from "../../assets/img/search-icon.svg";
import blockIcon from "../../assets/img/block.svg";
import unblockIcon from "../../assets/img/unblock.svg";
import reportedIcon from "../../assets/img/flag.svg";
import deleteIcon from "../../assets/img/delete.svg";
import noActiveUser from "../../assets/img/no_user_found.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  blockUsers,
  deleteUsers,
  getUserList,
  unBlockUsers,
} from "../../Utils/api-helper";
import { showToast, capitalizeText, showLoader } from "../../Utils/common-helper";
import { debounce } from "throttle-debounce";
import ConfirmAlert from "../Confirm-Alert";
import FirebaseImage from "../FirebaseImage";
let filterList = [
  { id: "ALL", name: "All" },
  { id: "ACTIVE", name: "Approved" },
  { id: "BLOCK", name: "Block" },
  { id: "REPORTED", name: "Reported" },
  { id: "REJECTED", name: "Rejected" },
  { id: "PENDING", name: "Pending" },
];
let roleList = [
  { id: "ALL", name: "All" },
  { id: "PILOT", name: "Pilot" },
  { id: "FLIGHT_ATTENDANT", name: "Flight Attendant" },
  { id: "GENERAL", name: "General" }
];
let payloadUsers;
function UserManagement(props) {
  const [users, setUsers] = useState([]);
  const [isAllcheckboxSelected, setIsAllCheckBoxSelected] = useState(false);
  const [isAnyCheckBoxSelected, setIsAnyCheckBoxSelected] = useState(false);
  const [isAnyActiveBlockedSelected, setIsAnyActiveBlockedSelected] =
    useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [blockusers, showBlock] = useState(false);
  const [unblockusers, showUnblock] = useState(false);
  const [deleteusers, showDelete] = useState(false);

  const handleBlock = () => {
    blockUsers(payloadUsers)
      .then((response) => {
        showToast(response.message, "success");
        const tempParams = { ...params };
        tempParams.page = 0;
        setParams(tempParams);
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          showToast(error.data.message, "error");
        }
      });
  };

  const handleunblock = () => {
    unBlockUsers(payloadUsers)
      .then((response) => {
        showToast(response.message, "success");
        const tempParams = { ...params };
        tempParams.page = 0;
        setParams(tempParams);
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          showToast(error.data.message, "error");
        }
      });
  };

  const handleDelete = () => {
    console.log('--------------------------------------delete---call--')
    deleteUsers(payloadUsers)
      .then((response) => {
        console.log('----------------res-',response)
        showToast(response.message, "success");
        const tempParams = { ...params };
        tempParams.page = 0;
        setParams(tempParams);
      })
      .catch((error) => {
        console.log('-------------------------------err',error)
        if (error.data && error.data.message) {
          showToast(error.data.message, "error");
        }
      });
  };

  const history = useHistory();
  let [totalPages, setTotalPages] = useState(0);
  let [preSearchText, setPreSearchText] = useState('');
  let [params, setParams] = useState({
    page: 0,
    size: 20,
    searchText: "",
    filter: "ALL",
    role: "ALL",
    sort: "NAME",
    order: "ASC",
    selectedFilter: "All",
    selectedRole: "All",
  });

  // PageLoad && everytime params change
  useEffect(() => {
    if (props.paramData && props.paramData.length) {
      const tempParams = { ...params };
      tempParams.filter = props.paramData[0].filters;
      tempParams.selectedRole = props.paramData[0].selectedRole;
      tempParams.selectedFilter = props.paramData[0].selectedFilter;
      tempParams.role = props.paramData[0].role;
      tempParams.page = 0;
      props.saveParamData([]);
      setTimeout(() => {
        setParams(tempParams);
      }, 0);
    } else {
      getUsers(params.page === 0 ? false : true);
    }
  }, [params]);

  const handleFilterClick = (selectedfilter, type) => {
    const tempParams = { ...params };
    if (type === "role") {
      tempParams.role = selectedfilter.id;
      tempParams.selectedRole = selectedfilter.name;
    } else {
      tempParams.filter = selectedfilter.id;
      tempParams.selectedFilter = selectedfilter.name;
    }
    tempParams.page = 0;
    setParams(tempParams);
  };

  const handleSortClick = () => {
    if (users.length === 0) {
      return;
    }
    const tempParams = { ...params };
    tempParams.order = tempParams.order === "ASC" ? "DESC" : "ASC";
    tempParams.page = 0;
    setParams(tempParams);
    setHasMore(true);
  };

  const handleClearSearch = () => {
    const tempParams = { ...params };
    tempParams.searchText = "";
    setParams(tempParams);
    let temp = { ...preSearchText };
    temp = '';
    setPreSearchText(temp);
  };

  const handleSearchChange = (text) => {
    setPreSearchText(text);
  };

  const submitSearch = (e) => {
    if (e.charCode == 13) {
      const tempParams = { ...params };
      tempParams.page = 0;
      tempParams.searchText = preSearchText;
      setParams(tempParams);
    }
  }

  const stopEventbubbling = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const checkBoxClicked = (e, user, index) => {
    let tempUsers = [...users];
    tempUsers[index].isSelected = e.target.checked ? true : false;
    setUsers(tempUsers);
    isAllChecked(tempUsers);
  };

  const checkAll = (e) => {
    let tempUsers = [...users];
    tempUsers.forEach((x) => (x.isSelected = e.target.checked ? true : false));
    setUsers(tempUsers);
    isAllChecked(tempUsers);
  };

  const handlecheckBoxChange = () => {
    //  empty event as it was controlled input errors on console
  };

  const isAllChecked = (tempUsers) => {
    if (tempUsers.length > 0) {
      let isAllchecked = tempUsers.every((_) => _.isSelected);
      let isSomechecked = tempUsers.some((_) => _.isSelected);
      setIsAllCheckBoxSelected(isAllchecked);
      setIsAnyCheckBoxSelected(isSomechecked);
      if (isSomechecked) {
        let areActiveSelect = false;
        let areBlockedSelect = false;
        tempUsers.forEach((element) => {
          if (element.isSelected && element.isBlocked) {
            areBlockedSelect = true;
          } else if (element.isSelected && !element.isBlocked) {
            areActiveSelect = true;
          }
        });
        if (areBlockedSelect && !areActiveSelect) {
          setIsAnyActiveBlockedSelected("Block");
        } else if (!areBlockedSelect && areActiveSelect) {
          setIsAnyActiveBlockedSelected("Active");
        } else {
          setIsAnyActiveBlockedSelected("");
        }
      }
    } else {
      setIsAllCheckBoxSelected(false);
      setIsAnyCheckBoxSelected(false);
      setIsAnyActiveBlockedSelected("");
    }
  };

  const onScrollUserList = () => {
    const tempParams = { ...params };
    tempParams.page++;
    if (totalPages > tempParams.page) {
      setParams(tempParams);
    } else {
      setHasMore(false);
    }
  };

  const getUsers = (onScroll) => {
    setIsLoading(true);
    getUserList(params)
      .then((response) => {
        console.log("===============>>>>>>",response)
        if (response && response.data) {
          props.saveParamData([])
          if (response.data.list && response.data.list.length > 0) {
            // initialize the value as false
            response.data.list.forEach((element) => {
              // for uncontrolled input warning/error
              element.isSelected = false;
            });

            let userList = [];
            if (onScroll) {
              // userList = [...users, response.data.list];
              userList = users.concat(response.data.list);
            } else {
              userList = [];
              userList = response.data.list;
            }
            setTotalPages(response.data.totalPages);
            if (response.data.list.length < params.size) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            setUsers(userList);
            isAllChecked(userList);
            if (params.page === 0) {
              let x = document.getElementById('tbody');
              x.scrollTop = 0;
            }
          } else {
            setUsers([]);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        props.saveParamData([]);
        setIsLoading(false);
        if (error.data && error.data.message) {
          showToast(error.data.message, "error");
        }
      });
  };

  const debounceFunc = debounce(1000, (num) => {
    // console.log("num:", num);
  });

  const handleBlockUnblock = (e, user) => {
    stopEventbubbling(e);
    let users = [];
    users.push(user.userId);
    payloadUsers = users;
    if (user.isBlocked) {
      showUnblock(true);
    } else {
      showBlock(true);
    }
  };

  const handleBlockUnblockSelected = (e) => {
    stopEventbubbling(e);
    let userIdList = users
      .filter((user) => user.isSelected)
      .map((rec) => {
        return rec.userId;
      });
    payloadUsers = userIdList;
    if (isAnyActiveBlockedSelected === "Block") {
      showUnblock(true);
    } else {
      showBlock(true);
    }
  };

  const handleDeleteClick = (e, user) => {
    stopEventbubbling(e);
    let userIdList = [];
    userIdList.push(user.userId);
    payloadUsers = userIdList;
    showDelete(true);
  };

  const handleDeleteSelected = (e) => {
    stopEventbubbling(e);
    let userIdList = users
      .filter((user) => user.isSelected)
      .map((rec) => {
        return rec.userId;
      });
    payloadUsers = userIdList;
    showDelete(true);
  };

  const goToUserDetails = (user) => {
    let obj = {
      selectedFilter: params.selectedFilter,
      filters: params.filter,
      selectedRole: params.selectedRole,
      role: params.role
    }
    let arr = [];
    arr.push(obj)
    props.saveParamData(arr)
    history.push({ pathname: "/user-details", state: { data: user } });
    localStorage.setItem('userID', user.userId)
  };

  const getuserStatus = (user) => {
    let finalStatus = '';
    if (user.isBlocked === true) {
      finalStatus = 'Blocked'
    } else if (user.isBlocked === false && user.reportedByDetails && user.reportedByDetails.length) {
      finalStatus = 'Reported'
    } else if (user.isBlocked === false && (user.reportedByDetails === null || user?.reportedByDetails?.length === 0) && user.approvedType) {
      finalStatus = user.approvedType
    }
    return capitalizeText(finalStatus);
  }


  return (
    <div className="page-container">
      <div className="sub-header-wrapper">
        <div className="title-wrapper">
          <span>{i18next.t("USER_MANAGEMENT")}</span>
        </div>

        <div className="action-wrapper">
          {isAnyCheckBoxSelected ? (
            <div className="header-buttons">
              {isAnyActiveBlockedSelected ? (
                <img
                  style={{ marginRight: 20 + "px" }}
                  onClick={(e) => handleBlockUnblockSelected(e)}
                  alt={
                    isAnyActiveBlockedSelected === "Block"
                      ? "Unblock Selected"
                      : "Block Selected"
                  }
                  title={
                    isAnyActiveBlockedSelected === "Block"
                      ? "Unblock Selected"
                      : "Block Selected"
                  }
                  src={
                    isAnyActiveBlockedSelected === "Block"
                      ? unblockIcon
                      : blockIcon
                  }
                />
              ) : (
                ""
              )}
              <img
                onClick={(e) => handleDeleteSelected(e)}
                title="Delete Selected"
                alt="Delete Selected"
                src={deleteIcon}
              />
            </div>
          ) : (
            ""
          )}
          <div className="dropdown ">
            <span className="dropdown-label">{i18next.t("ROLE_LABEL")}</span>
            <button
              className="btn  dropdown-toggle"
              type="button"
              id="dropdownMenu2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div
                className="selected-dd-text"
                style={{ width: '120px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                title={params.selectedRole}>{params.selectedRole}</div>
              <img
                className="dropDownArrow"
                alt="dropdown"
                src={dropDownArrow}
              />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
              {roleList.map((filter) => (
                <li
                  key={filter.id}
                  onClick={() => handleFilterClick(filter, "role")}
                >
                  <button className="dropdown-item" type="button">
                    {filter.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="dropdown ">
            <span className="dropdown-label">{i18next.t("Status")}</span>
            <button
              className="btn  dropdown-toggle"
              type="button"
              id="dropdownMenu2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {params.selectedFilter}
              <img
                className="dropDownArrow"
                alt="dropdown"
                src={dropDownArrow}
              />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
              {filterList.map((filter) => (
                <li
                  key={filter.id}
                  onClick={() => handleFilterClick(filter, "filter")}
                >
                  <button className="dropdown-item" type="button">
                    {filter.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <span className="search-container search-form">
            <span className="ipad-fixes">{' '}</span>
            <img className="search-icon search-icon-user" alt="search" src={searchIcon} />
            <input
              type="text"
              name="search"
              id="search"
              value={preSearchText}
              onChange={(event) => handleSearchChange(event.target.value)}
              onKeyPress={(e) => submitSearch(e)}
              placeholder="Search"
              className="form-control search-background"
            />
            {/* {params.searchText && params.searchText.length > 0 ? ( */}
            {preSearchText && preSearchText.length > 0 ? (
              <img
                className="clear-search clear-search-user"
                onClick={() => handleClearSearch()}
                alt="cross"
                src={clearSearch}
              />
            ) : (
              ""
            )}
          </span>
        </div>
      </div>
      <div className="table-conatiner">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th className="width-20 name-col" scope="col">
                <div className="checkbox-container-rem header-checkbox">
                  <div className="sort-container">
                    <span>{i18next.t("NAME")}</span>
                    <img
                      className="sort-icon"
                      alt="dropdown"
                      src={sort}
                      onClick={() => handleSortClick()}
                    />
                  </div>
                </div>
              </th>
              <th className="width-225" scope="col">
                {i18next.t("EMAIL_ID")}
              </th>
              <th className="width-125" scope="col">
                {i18next.t("ROLE")}
              </th>
              <th className="width-10" scope="col">
                {i18next.t("GENDER")}
              </th>
              <th className="width-10" scope="col">
                {i18next.t("STATUS")}
              </th>
              <th className="width-10" scope="col">

              </th>
            </tr>
          </thead>
          <tbody id="tbody">
            <InfiniteScroll
              dataLength={users.length} //This is important field to render the next data
              next={onScrollUserList}
              hasMore={hasMore}
              // loader={<h4>Loading...</h4>}
              scrollableTarget="tbody"
            >
              {users.map((user, index) => {
                return (
                  <tr className="row-hover" key={user.userId + 'id00' + index} onClick={() => goToUserDetails(user)}>
                    <td className="width-20 name-col">
                      <div className="checkbox-container-rem  body-checkbox">
                        <div
                          className="user-detail-container"
                        >
                          <FirebaseImage
                            className=""
                            src={user.profileImagePath}
                          />
                          <span>{`${user.firstName} ${user.lastName}`}</span>
                        </div>
                      </div>
                    </td>
                    <td
                      className="width-225"
                    >
                      {user.email}
                    </td>

                    <td
                      className="width-125 role"
                    >
                      {user.role && user.role.toLowerCase()}
                    </td>
                    <td
                      className="width-10"
                    >{`${user?.gender ? capitalizeText(user.gender) : '-'}`}</td>

                    <td
                      className="width-10"
                    ><span className={getuserStatus(user) === 'Pending' ? 'pending-text' : getuserStatus(user) === 'Approved' ? 'approve-text' : getuserStatus(user) === 'Rejected' ? 'rejected-text' : getuserStatus(user) === 'Blocked' ? 'blocked-text' : getuserStatus(user) === 'Reported' ? 'reported-text' : null}>
                        {getuserStatus(user)}
                      </span></td>

                    <td
                      className="width-10"
                    >
                      <div className="genderBtn">
                        {user.status ? (
                          <span
                            className={`gender
                      ${user.status.toLowerCase() === "approved"
                                ? "approved"
                                : ""
                              }
                      ${user.status.toLowerCase() === "pending" ? "pending" : ""
                              }
                      ${user.status.toLowerCase() === "rejected"
                                ? "rejected"
                                : ""
                              }
                      `}
                          >
                            {user.status}
                          </span>
                        ) : (
                          ""
                        )}
                        {user.approvedType === "APPROVED"
                          ? <div className="hover-button-container">
                            <div className="hover-button-section">
                              <img
                                onClick={(e) => handleBlockUnblock(e, user)}
                                className=""
                                title={user.isBlocked ? "Unblock" : "Block"}
                                alt={user.isBlocked ? "Unblock" : "Block"}
                                src={user.isBlocked ? unblockIcon : blockIcon}
                              />
                              {user.isReported ? (
                                <img
                                  onClick="viewReportedComment(user,i);$event.stopPropagation()"
                                  alt="Report"
                                  title="Report"
                                  src={reportedIcon}
                                />
                              ) : (
                                ""
                              )}
                              <img
                                onClick={(e) => handleDeleteClick(e, user)}
                                title="Delete"
                                alt="Delete"
                                src={deleteIcon}
                              />
                            </div>
                          </div> : null}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </InfiniteScroll>
          </tbody>
          {users && users.length === 0 && !isLoading && (
            <div className="text-center no-data">
              <img src={noActiveUser} alt="no active user" />
              <h6 className="no-data-title pt-3">
                {i18next.t("NO_USER_FOUND")}{" "}
              </h6>
            </div>
          )}
        </table>
      </div>

      <ConfirmAlert
        confirmMsg={i18next.t("BLOCK_USER_CONFIRMATION")}
        positiveBTN={i18next.t("BLOCK_LABEL")}
        negativeBTN={i18next.t("CANCEL_LABEL")}
        isOpen={blockusers}
        positiveCallback={() => {
          showBlock(false);
          handleBlock();
        }}
        negativeCallback={() => showBlock(false)}
      />
      <ConfirmAlert
        confirmMsg={i18next.t("UNBLOCK_USER_CONFIRMATION")}
        positiveBTN={i18next.t("UNBLOCK_LABEL")}
        negativeBTN={i18next.t("CANCEL_LABEL")}
        isOpen={unblockusers}
        positiveCallback={() => {
          showUnblock(false);
          handleunblock();
        }}
        negativeCallback={() => showUnblock(false)}
      />
      <ConfirmAlert
        confirmMsg={i18next.t("DELETE_USER_CONFIRMATION")}
        positiveBTN={i18next.t("DELETE_LABEL")}
        negativeBTN={i18next.t("CANCEL_LABEL")}
        isOpen={deleteusers}
        positiveCallback={() => {
          showDelete(false);
          handleDelete();
        }}
        negativeCallback={() => showDelete(false)}
      />
      {showLoader(isLoading)}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  paramData: state.userManagement.paramData
});

export default connect(mapStateToProps, { saveParamData })(UserManagement);
