import { JOB_DATA, HIRING_BOARD_PARAMS } from "../types";

const initialState = {
  selectedJobData: null,
  paramData: []
};

export default function hiringBoardReducer(state = initialState, action) {
    switch (action.type) {
      case JOB_DATA:
        return {
          ...state,
          selectedJobData: action.payload,
        };
      
      case HIRING_BOARD_PARAMS:
        return {
          ...state,
          paramData: action.payload,
        };
  
      default:
        return state;
    }
  }