import React from 'react';
import SubscriptionDetails from '../../Components/Subscription-Details';

function index(props) {
  
    return (
      <SubscriptionDetails />
    );
  }
  
  export default index;