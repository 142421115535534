import { SELECTED_TRIP_ID } from "../types";

const initialState = {
  selectedTripId: null,
};

export default function tripManagementReducer(state = initialState, action) {
    switch (action.type) {
      case SELECTED_TRIP_ID:
        return {
          ...state,
          selectedTripId: action.payload,
        };
  
      default:
        return state;
    }
  }