import React from "react";
import { Formik, Form } from "formik";

import styles from "./AppForm.module.scss";


function AppForm({ initialValues, validationSchema, onSubmit, children, onCheckValid }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validate={onCheckValid}
    >
      {() => <Form className={styles.form}>{children}</Form>}
    </Formik>
  );
}

export default AppForm;
