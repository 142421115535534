import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Layout.scss";
import Header from "../Header/index";
import Sidebar from "../Sidebar/index";
import { routing } from "../../Router";
import { setAuthToken } from "../../Utils/api-helper";
import { initializeApp } from "../../Utils/firebase-helper";
import Emitter from "../../Utils/Emitter";
import { saveAuthData } from "../../redux/actions/auth.actions";

function Layout(props) {
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [isAuthenticated, SetIsAuthenticated] = useState(false);

  const onChangeStatus = (status) => {
    setSidebarStatus(status);
  };

  useEffect(() => {
    if (props?.authData) {
      SetIsAuthenticated(true);
      setAuthToken(props.authData.accessToken, props.authData.refreshToken);
      initializeApp(props.authData.firebaseToken);
    } else {
      SetIsAuthenticated(false);
      setAuthToken(undefined, undefined);
    }
    // To update the access token and refresh token after refresh in redux
    Emitter.on("UPDATE_TOKEN", (data) => {
      let tempAuthData = { ...props.authData };
      tempAuthData.accessToken = data.access_token;
      tempAuthData.refreshToken = data.refresh_token;
      props.saveAuthData(tempAuthData);
    });
    // unmounting.
    return () => {
      Emitter.off("UPDATE_TOKEN");
    };
  }, [props.authData]);



  return (
    <div className="layout-wrapper">
      {isAuthenticated ? (
        <>
          <Header
            sidebarStatus={sidebarStatus}
            onStatusChange={onChangeStatus}
          />
          <Sidebar sidebarStatus={sidebarStatus} onClose={onChangeStatus} />
        </>
      ) : null}
      <div
        className={`page-wrapper ${!isAuthenticated ? "no-auth-pages" : ""}`}
      >
        {routing(props.authData)}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
});

export default connect(mapStateToProps, { saveAuthData })(Layout);
