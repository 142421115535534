import React from 'react';

import OtpVerification from "../../Components/OtpVerification";
import AppScreen from "../../Components/AppScreen";
import Logo from "../../Components/Logo";
import Card from "../../Components/Card";
import i18next from 'i18next';

function OTPVerification(props) {
  
  return (
    <AppScreen>
    <Card>
      <Logo
        title={i18next.t('OTP_VERIFICATION_T')}
        subtitle={i18next.t('OTP_VERIFICATION_ST')}
      />
      <OtpVerification />
    </Card>
  </AppScreen>
  );
}

export default OTPVerification;