import { AUTH_DATA, USER_DATA } from "../types";

const initialState = {
  authData: undefined,
  rememberMeData: undefined,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_DATA:
      return {
        ...state,
        authData: action.payload,
      };
    case USER_DATA:
      return {
        ...state,
        rememberMeData: action.payload,
      };

    default:
      return state;
  }
}
