import { USER_MANAGEMENT_PARAMS } from "../types";

const initialState = {
  paramData: [],
};

export default function userManagementReducer(state = initialState, action) {
    switch (action.type) {
      case USER_MANAGEMENT_PARAMS:
        return {
          ...state,
          paramData: action.payload,
        };
  
      default:
        return state;
    }
  }