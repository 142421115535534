import React from "react";
import i18next from "i18next";
import { Link, useHistory } from "react-router-dom";

import AppForm from "../AppForm";
import {TextField }from "../TextField";
import { forgotPasswordValidation } from "../../Utils/ValidationService";
import {forgotPassword} from '../../Utils/api-helper'
import {showToast}  from '../../Utils/common-helper'


function ForgotPassword(props) {
  const { initialValues, validationSchema } = forgotPasswordValidation();
  const history = useHistory();
  const handleSubmit = (values) => {
    const payload ={
      "emailId": values.email,
      "firstName": "",
      "lastName": "",
      "otpType": "FORGOT_PASSWORD",
      "requestFrom": "ADMIN_CONSOLE"
    }
   
    forgotPassword(payload)
    .then(response => {
      if (response && response.data && response.code === 200) {
        showToast(response.message,'success');
        let path = "/otp-verification";
        history.push(`${path}?email=${values.email}`)
      } else {
        showToast(response.message,'error');
      }
    })
    .catch(error => {
      showToast(error.data.message,'error');
    })
    
  };

  return (
    <>
      <AppForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <TextField label={i18next.t("EMAIL_LABEL")} name="email" type="email" />

        <div className="d-grid gap-2">
          <button
            className={`btn btn-primary ripple loginBtn`}
            type="submit"
          >
            {i18next.t("SUBMIT")}
          </button>
        </div>
      </AppForm>
      <p className="backToSignIn">
        {i18next.t("BACK_TO")}
        <Link to="/login"> {i18next.t("LOGIN")}</Link>
      </p>
    </>
  );
}

export default ForgotPassword;
