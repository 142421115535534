import React, { useState } from "react";
import { useField, ErrorMessage } from "formik";
import "../TextField/TextField.scss"
import hideIcon from "../../assets/img/hide.svg";
import showIcon from "../../assets/img/show.svg";

 function TextArea({ label, type, ...props }) {
  const [field, meta] = useField(props);
  const [assignedType, setAssignedType] = useState(type);
  const changePasswordType = () => {
    const _assignedType = assignedType === "password" ? 'text' : "password";
    setAssignedType(_assignedType);
  };
  return (
    <div className="input-container">
      <div className="form-group has-float-label">
        <textarea
          className={`form-control shadow-none ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          type={assignedType}
          {...props}
          placeholder=" "
          name={field.name}
          id={field.name}
        />
        <label htmlFor={field.name}>{label}</label>
      </div>
      <ErrorMessage component="span" name={field.name} className="error" />
    </div>
  );
}

export default TextArea;