import React from "react";
import { Link, useHistory } from "react-router-dom";

import AppForm from "../AppForm";
import {TextField }from "../TextField";
import { resetPasswordValidation } from "../../Utils/ValidationService";
import i18next from "i18next";
import {resetPassword} from '../../Utils/api-helper'
import {showToast}  from '../../Utils/common-helper'


function ResetPassword(props) {
  const { initialValues, validationSchema } = resetPasswordValidation();
  const history = useHistory();

  const handleSubmit = (values) => {
    const payload = {
      "authPassword": values.password
    };
    resetPassword(payload)
      .then((response) => {
        if (response && response.data && response.code === 200) {
          showToast(response.message, "success");
          let path = "/login";
          history.replace(path);
        } else {
          showToast(response.message, "error");
        }
      })
      .catch((error) => {
        showToast(error.data.message,'error');

      });
    
  };

  return (
    <>
      <AppForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <TextField
          label={i18next.t("NEW_PASSWORD")}
          name="password"
          type="password"
        />
        <TextField
          label={i18next.t("CONFIRM_PASSWORD")}
          name="confirmPassword"
          type="password"
        />

        <div className="d-grid gap-2">
          <button
            className={`btn btn-primary ripple loginBtn`}
            type="submit"
          >
            {i18next.t("SUBMIT")}
          </button>
        </div>
      </AppForm>
      <p className="backToSignIn">
        {i18next.t("BACK_TO")}
        <Link to="/login">{i18next.t("LOGIN")}</Link>
      </p>
    </>
  );
}

export default ResetPassword;
