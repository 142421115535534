import React from "react";
import { Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./Components/ProtectedRoute";
import NotProtectedRoute from './Components/NotProtectedRoute';

import Login from "./screens/login";
import ForgotPassword from "./screens/forgot-password";
import OtpVerification from "./screens/otp-verification";
import ResetPassword from "./screens/reset-password";
import UserDetailsPage from "./screens/user-details";
import UserManagement from "./screens/user-management";
import MasterDataManagement from "./screens/masterData-management";
import TripManagement from './screens/trip-management'
import TripDetails from './screens/trip-details';
import HiringBoard from "./screens/hiring-board";
import SubscriptionDetails from "./screens/subscription-details";
import JobDetails from './Components/Job_Details';
import UserReported from "./Components/User-Reported";



export function routing(authData) {
  return (
    <>
      <Switch>
        <ProtectedRoute
          isAuth={authData}
          path="/user-management"
          component={UserManagement}
        />
        <ProtectedRoute
          isAuth={authData}
          path="/user-details"
          component={UserDetailsPage}
        />
        <ProtectedRoute
          isAuth={authData}
          path="/master-data-management"
          component={MasterDataManagement}
        />

        <ProtectedRoute
          isAuth={authData}
          path="/trip-management"
          component={TripManagement}
        />

        <ProtectedRoute
          isAuth={authData}
          path="/trip-details"
          component={TripDetails}
        />
          <ProtectedRoute
          isAuth={authData}
          path="/hiring-board"
          component={HiringBoard}
        />
          <ProtectedRoute
          isAuth={authData}
          path="/subscription-details"
          component={SubscriptionDetails}
        />
          <ProtectedRoute
          isAuth={authData}
          path="/job-details"
          component={JobDetails}
        />
         <ProtectedRoute
          isAuth={authData}
          path="/user-reported"
          component={UserReported}
        />
        <NotProtectedRoute  isAuth={authData} path="/reset-password" component={ResetPassword} />
        <NotProtectedRoute  isAuth={authData} path="/otp-verification" component={OtpVerification} />
        <NotProtectedRoute  isAuth={authData} path="/forgot-password" component={ForgotPassword} />
        <NotProtectedRoute  isAuth={authData} path="/login" component={Login} />
        <Redirect path="/" exact to={authData ? "/user-management" : "/login"} />
        <Redirect to={authData ? "/user-management" : "/login"} />
      </Switch>
    </>
  );
}
