import React from 'react';

import TripManagement from '../../Components/Trip-Management';

function TripManagementPage(props) {
  
  return (
    <TripManagement />
  );
}

export default TripManagementPage;