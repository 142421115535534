import React, { useEffect, useState } from "react";
import HeaderLogo from '../../assets/img/header-logo.svg';
import "./Header.scss";


function CommonHeader(props) {
    const [toggleValue, setToggleValue] = useState(false);

    const onToggle = () => {
        setToggleValue(!toggleValue);
        props.onStatusChange(!toggleValue);
    }

    useEffect(() => {
        setToggleValue(props.sidebarStatus);
    }, [props.sidebarStatus])

    return(
        <div className="header-wrapper">
            <div className="logo-wrapper">
                <img alt="logo" src={HeaderLogo} />
            </div>
            <div className="menu-toggle" onClick={onToggle} ></div>
            <div className="user">
                Admin
                <div className="profile-picture"></div>
            </div>
        </div>
    );
}

export default CommonHeader;