import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import i18next from "i18next";
import {
  forgotPassword,
  setAuthToken,
  verifyOTP,
} from "../../Utils/api-helper";
import { showToast } from "../../Utils/common-helper";

import * as storageHelper from "../../Utils/local-storage-helper";
import queryString from "query-string";
import { saveAuthData } from "../../redux/actions/auth.actions";

function OtpVerification(props) {
  const [otp, setOtp] = useState("");
  const [hasErrored, setHasErrored] = useState(false);
  const [isResendOTP, setIsResendOTP] = useState(false);
  const [remainingTime, setRemainingTime] = useState(300);
  const history = useHistory();
  const location = useLocation();
  const time = useRef(null)
  const queryParams = queryString.parse(location.search);
  // let time;
  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };


  const resendOtp = () => {
    const payload = {
      emailId: queryParams.email,
      firstName: "",
      lastName: "",
      otpType: "FORGOT_PASSWORD",
      requestFrom: "ADMIN_CONSOLE"
    };
    forgotPassword(payload)
      .then((response) => {
        if (response && response.data) {
          setIsResendOTP(false)
          timer();
          showToast(response.message, "success");
          // let path = "/otp-verification";
          // history.push(`${path}?email=${queryParams.email}`);
        }
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          showToast(error.data.message, "error");
        }
      });
  };
  const secToMinutes = (sec) =>{
    let minutes = ("0" + Math.floor(sec / 60)).slice(-2);
    let seconds = ("0" + sec % 60).slice(-2);
    return minutes + ":" + seconds;
  }

  const handleClick = () => {
    if (otp && otp.length === 6) {
      setHasErrored(false);
      const payload = {
        email: queryParams.email,
        oauth2ClientId: "",
        otp: otp,
      };
      verifyOTP(payload)
        .then((response) => {
          if (response && response.data && response.code === 200) {
            clearTimer();
            showToast(response.message, "success");
            saveAuthData(response.data);
            setAuthToken(response.data.accessToken, response.data.refreshToken);
            storageHelper.set("accessToken", response.data.accessToken);
            storageHelper.set("refreshToken", response.data.refreshToken);
            let path = "/reset-password";
            history.replace(path);
          } else {
            showToast(response.message, "error");
          }
        })
        .catch((error) => {
          if (error.data && error.data.message) {
            showToast(error.data.message, "error");
          }
        });
    } else {
      setHasErrored(true);
    }
  };

  

  const timer = () => {
    let today = new Date();
    today.setSeconds(today.getSeconds() + 30);
    let countDownDate = today.getTime();
    
    let i = 300;
    let j = 0;

      time.current = setInterval(() => {
      // Get today's date and time
      j = j + 1;
      let now = new Date().getTime();
      let distance = countDownDate - now;
      console.log('----------------------------------------------gis',distance / 1000)
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      console.log('----------------------secs',seconds)
      let sec = seconds.toString();
      if (seconds < 10) {
        sec = '0' + seconds.toString();
      }
      setRemainingTime("00:" + sec);
      document.getElementById('demo').innerHTML = secToMinutes(i-j);

      if (distance <= 0) {
        clearInterval(time.current);
        document.getElementById("demo").innerHTML = "";
        setRemainingTime("00:0-1")
        setIsResendOTP(true)
      }
      // if (remainingTime == '00:0-1') { 
      //   setIsResendOTP(true)
      // }
      return isResendOTP;
    }, 1000);
  }
  // const timer = () =>{
  //   let i = 1;
  //   time.current = setInterval(() =>{
  //     console.log('-----------------------------------------timer')
  //     setRemainingTime(remainingTime - i);
  //     i++;
  //     console.log('-------------------------------------------,i',i)
  //     document.getElementById('demo').innerHTML = secToMinutes(remainingTime);
  //     if(remainingTime == 0){
  //       console.log('-------------------------------------if---else')
  //       clearTimer()
  //     }
  //   },1000)
  // }

   const clearTimer = () => {
    clearInterval(time.current);
    document.getElementById("demo").innerHTML = "";
    setIsResendOTP(true)
  }

  useEffect(() => {
    timer();
  }, [])

  return (
    <>
      <div className="otp-input-container" >
        <OtpInput
          value={otp}
          hasErrored={hasErrored}
          onChange={handleChange}
          numInputs={6}
          containerStyle="otp-container"
          focusStyle="on-focus"
          errorStyle="otp-invalid"
          isInputNum
          shouldAutoFocus
          inputStyle="otp-input"
        />
        {hasErrored && (
          <div className="resend-otp-container">
            <span className="error">{i18next.t("OTP_REQUIRED")}</span>
          </div>
        )}
      </div>
      <div className="resend-timer-wrapper">
        <div id="demo"></div>
        
        <div className="resend-otp-container">
          <p onClick={isResendOTP ? () => resendOtp() : (e) => e.stopPropagation()}
          className={`resend-OTP ${isResendOTP === false ? 'opacity-50' : ''}`}
          aria-disabled={!isResendOTP}>
            {i18next.t("RESEND_OTP")}
          </p>
        </div>
      </div>

      <div className="d-grid gap-2">
        <button
          className={`btn btn-primary ripple loginBtn`}
          type="submit"
          onClick={handleClick}
        >
          {i18next.t("SUBMIT")}
        </button>
      </div>

      <p className="backToSignIn">
        {i18next.t("BACK_TO")}
        <Link to="/login"> {i18next.t("LOGIN")}</Link>
      </p>
    </>
  );
}

export default OtpVerification;
