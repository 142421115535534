import React, { useState, useEffect } from "react";
import "./Sidebar.scss";
import i18next from "i18next";
import HeaderLogo from "../../assets/img/header-logo.svg";
import logoutIcon from "../../assets/img/logout_icon.svg";
import usersIcon from "../../assets/img/user_icon.svg";
import aircraftsIcon from "../../assets/img/aircrafts_icon.svg";
import masterDataIcon from "../../assets/img/masterData_icon.svg";
import tripsIcon from "../../assets/img/trips_icon.svg";
import adsIcon from "../../assets/img/ads_icon.svg";
import jobPostsIcon from "../../assets/img/jobposts_icon.svg";
import subscriptionsIcon from "../../assets/img/subscriptions_icon.svg";
import reporedEntityIcon from "../../assets/img/reportedEntity_icon.svg";
import ReviewsIcon from "../../assets/img/reviews_icon.svg";
import { useHistory, useLocation } from "react-router-dom";
import { saveAuthData } from "../../redux/actions/auth.actions";
import { saveParamData } from "../../redux/actions/user-management.action";
import { connect } from "react-redux";
import Emitter from "../../Utils/Emitter";

import ConfirmAlert from "../Confirm-Alert/index";
import { showToast } from "../../Utils/common-helper";

function Sidebar(props) {
  const location = useLocation();
  const history = useHistory();

  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const toggle1 = () => setModal1(!modal1);
  const toggle2 = () => setModal2(!modal2);

  const closeConfirmModal = (type) => {
    if(type === 'logout'){
      setModal1(false);
    }else if(type === 'comingSoon') {
      setModal2(false);
    }
  }

  const menuList = [
    {
      name: "USERS_LABEL",
      iconPath: usersIcon,
      urlPath: ["/user-management", "/user-details"],
      goToURL: "",
    },
    {
      name: "AIRCREAFTS_LABEL",
      iconPath: aircraftsIcon,
      urlPath: "/",
      goToURL: "",
    },
    {
      name: "MASTER_DATA_LABEL",
      iconPath: masterDataIcon,
      urlPath: ["/master-data-management"],
      goToURL: "",
    },
    {
      name: "TRIPS_LABEL",
      iconPath: tripsIcon,
      urlPath: ["/trip-management", "/trip-details"],
      goToURL: "",
    },
    {
      name: "HIRING_BOARD_LABEL",
      iconPath: jobPostsIcon,
      urlPath: ["/hiring-board", "/job-details", "/user-reported"],
      goToURL: "",
    },
    {
      name: "ADS_LABEL",
      iconPath: adsIcon,
      urlPath: "/",
      goToURL: "",
    },
    {
      name: "SUBSCRIPTIONS_LABEL",
      iconPath: subscriptionsIcon,
      urlPath:["/subscription-details"],
      goToURL: "",
    },
    {
      name: "REPORTED_ENTITY_LABEL",
      iconPath: reporedEntityIcon,
      urlPath: "/",
      goToURL: "",
    },
    {
      name: "REVIEWS_LABEL",
      iconPath: ReviewsIcon,
      urlPath: "/",
      goToURL: "",
    },
    {
      name: "LOGOUT_LABEL",
      iconPath: logoutIcon,
      urlPath: ["/logout"],
      goToURL: "",
    },
  ];

  const onClose = () => {
    setSidebarStatus(false);
    props.onClose(false);
    // toggle2();
  };

  const logout = (isOauthError) => {
    setSidebarStatus(false);
    props.onClose(false);
    props.saveAuthData(undefined);
    const path = "/login";
    history.replace(path);
    if(isOauthError){
      showToast(i18next.t("SESSION_TIMOUT"), "info");
    }
  };

  const sidebarClose = () => {
    setSidebarStatus(false);
    props.onClose(false);
    closeConfirmModal('comingSoon')
  };

  const goToScreen = (urlpath) => {
    props.saveParamData([]);
    if(urlpath === '/logout'){
      toggle1();
    } else {
      history.push(urlpath);
    }
    onClose();
  }

  useEffect(() => {
    setSidebarStatus(props.sidebarStatus);
  }, [props.sidebarStatus]);

  useEffect(() => {
  }, [location]);

  // It takes a function
  useEffect(() => {
    Emitter.on("LOGOUT", () => {
      logout(true);
    });
    // unmounting.
    return () => {
      Emitter.off("LOGOUT");
    };
  }, []);

  return (
    <>
      <div
        className={`overlay-div ${sidebarStatus ? "show-overlay" : ""}`}
        onClick={onClose}
      ></div>
      <div
        className={`sidebar-wrapper ${
          sidebarStatus ? "open-sidebar" : "close-sidebar"
        }`}
      >
        <div className="sidebar-subwrapper">
          <div className="logo-wrapper">
            <img alt="logo" src={HeaderLogo} />
          </div>

          <div className="menu-list">
            <ul>
              {menuList.map((menu, index) => {
                return (
                  <li
                    className={
                      menu.urlPath.includes(location.pathname)
                        ? "selected-option"
                        : ""
                    }
                    key={`menu${index}`}
                    onClick={
                      index > 0 && index !== 2 && index !== 3 && index !== 4 && index !== 6 && index !== (menuList.length - 1) ? () => toggle2() : (e) => goToScreen(menu?.urlPath[0])
                    }
                  >
                    <i
                      className="menu-icon"
                      style={{ backgroundImage: `url(${menu.iconPath})` }}
                    ></i>
                    <span className={index + 1 === menuList.length ? 'logout-text' : ''}>{i18next.t(menu.name)}</span>
                  </li>
                );
              })}
            </ul>

            {/* <div className="logout-wrapper">
              <div className="logout" onClick={() => toggle1()}>
                <img src={logoutIcon} alt="logout_icon" />
                <span>{i18next.t("LOGOUT_LABEL")}</span>
              </div>
            </div> */}
            
          </div>
        </div>
      </div>
      <ConfirmAlert
        confirmMsg={i18next.t("LOGOUT_CONFIRMATION")}
        positiveBTN={i18next.t("LOGOUT_LABEL")}
        negativeBTN={i18next.t("CANCEL_LABEL")}
        isOpen={modal1}
        positiveCallback={logout}
        negativeCallback={() => closeConfirmModal('logout')}
      />

      <ConfirmAlert
        confirmMsg={i18next.t("COMING_SOON_MSG")}
        negativeBTN={i18next.t("OK_LABEL")}
        isOpen={modal2}
        negativeCallback={sidebarClose}
      />
    </>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveAuthData, saveParamData })(Sidebar);
// export default Sidebar;
