import React from 'react';

import TripDetails from '../../Components/Trip-Details';

function UserDetailsPage(props) {
  
  return (
    <TripDetails />
  );
}

export default UserDetailsPage;