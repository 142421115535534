import React, { useState } from 'react';
import './Infocard.scss';

import PDFViewer from '../PDF-Viewer/index';
import i18next from 'i18next';

const InfoCard = (props) => {
    const [modal, setModal] = useState(false);
    const [fileDetails, setFileDetails] = useState(null);



    const toggle = (fileName, filePath) => {
        let obj = {
            filename: fileName,
            filepath: filePath
        }
        setFileDetails(obj);
        setModal(!modal);
    }

    return(
        <div className="info-card-wrapper">
            <div className={`info-card ${props?.viewType === 'about' ? 'about-div-margin' : ''}`}>
                <div className={`info-card-title ${props?.viewType === 'about' ? 'bg-white' : ''}`}>
                    <span>{props?.title}</span>
                </div>
                <div className="info-card-body">
                    {props?.viewType === "list"
                    ?    <div className="text-list">
                            <ul>
                                {props.eduData.map((edu, index) => {
                                    return(
                                        <li key={`edu00${index}`}>{edu.name}</li>
                                    );
                                })}
                            </ul>
                        </div>

                    :   props?.viewType === "details"
                    ?   <div className="detail-list">
                            {props.data.map((elm, index) => {
                                return(
                                    <div className="detail-row" key={`data00${index}`}>
                                        {elm?.title && elm?.title !== ''
                                            ? <div className="row-div">
                                                <div className="key-text">{i18next.t('TITLE_LABEL')}:</div>
                                                <div className="value-text">{elm.title}</div>
                                            </div>
                                            : null}

                                        {elm?.description && elm?.description !== ''
                                            ? <div className="row-div">
                                                <div className="key-text">{i18next.t('DESCRIPTION_LABEL')}:</div>
                                                <div className="value-text">
                                                    {elm.description}
                                                </div>
                                            </div>
                                            : null}

                                        {elm?.pdfPath && elm?.pdfPath !== ''
                                            ? <div className="file-details">
                                                <div className="file-placeholder" onClick={() => toggle(elm.pdfName, elm.pdfPath)}>{i18next.t('PDF_LABEL')}</div>
                                                <div className="file-info" onClick={() => toggle(elm.pdfName, elm.pdfPath)}>
                                                    <div className="file-name" title={elm?.pdfName}>{elm?.pdfName}</div>
                                                    <div className="file-size">{elm?.pdfSize} MB</div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                )
                            })}
                        </div>
                    :   props?.viewType === "experienceView"
                    ?   <div className="detail-list">
                            {props?.experienceData?.map((elm, index) => {
                                return(
                                    <div className="detail-row" key={`experienceData00${index}`}>
                                        {elm?.totalFlightHours && elm?.totalFlightHours !== ''
                                        ?   <div className="row-div">
                                                <div className="key-text key-text-exp">{i18next.t('TOTAL_FLIGHT_HRS_LABEL')}:</div>
                                                <div className="value-text">{elm.totalFlightHours}</div>
                                            </div>
                                        :   elm?.experienceYear && elm?.experienceYear !== ''
                                        ?   <div className="row-div">
                                                <div className="key-text key-text-exp">{i18next.t('YEARS_OF_EXPERIENCE_LABEL')}:</div>
                                                <div className="value-text">{`${elm.experienceYear}yr ${elm.experienceMonth}mo`}</div>
                                            </div>
                                        :   null}

                                        {elm?.experiences && elm?.experiences !== ''
                                            ? <div className="row-div">
                                                <div className="key-text key-text-exp">{i18next.t('EXPERIENCES_LABEL')}:</div>
                                                <div className="value-text">
                                                    {elm?.experiences}
                                                </div>
                                            </div>
                                            : null}

                                        {elm?.pdfName && elm?.pdfName !== '' ? <div className="file-details">
                                            <div className="file-placeholder" onClick={() => toggle(elm.pdfName, elm.pdfPath)}>{i18next.t('PDF_LABEL')}</div>
                                            <div className="file-info" onClick={() => toggle(elm.pdfName, elm.pdfPath)}>
                                                <div className="file-name" title={elm?.pdfName}>{elm.pdfName}</div>
                                                <div className="file-size">{elm.pdfSize} MB</div>
                                            </div>
                                        </div> : null}
                                    </div>
                                )
                            })}
                        </div>
                    :   props?.viewType === "aircraftInfoview"
                    ?   <>
                        <div className="detail-list">
                            <div className="detail-row">
                                {props?.companyName && props?.companyName !== ''
                                ?   <div className="row-div">
                                        <div className="key-text">{i18next.t('COMPANY_NAME_LABEL')}:</div>
                                        <div className="value-text">{props?.companyName}</div>
                                    </div> 
                                :   null}
                            </div>
                        </div>
                        <div className="detail-list aircraft-type-list">
                            {props?.aircraftInfoData?.map((elm, index) => {
                                return(
                                    <div className="detail-row aircraft-info-details" key={`aircraftInfoview00${index}`}>
                                        {elm?.masterAircraftTypeDTO && elm?.masterAircraftTypeDTO.type && elm?.masterAircraftTypeDTO.type !== ''
                                        ?   <div className="row-div">
                                                <div className="key-text">{i18next.t('AIRCRAFT_TYPE_LABEL')}:</div>
                                                <div className="value-text">{elm.masterAircraftTypeDTO.type}</div>
                                            </div>
                                        :   null}

                                        {elm?.masterAircraftTailNumberDTO && elm?.masterAircraftTailNumberDTO.tailNumber && elm?.masterAircraftTailNumberDTO.tailNumber !== ''
                                            ? <div className="row-div">
                                                <div className="key-text">{i18next.t('AIRCRAFT_TAIL_NO_LABEL')}:</div>
                                                <div className="value-text">
                                                    {elm?.masterAircraftTailNumberDTO.tailNumber}
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                )
                            })}
                        </div>
                        </>
                    :   props?.viewType === "degreeView"
                    ?   <div className="detail-list">
                            {props?.degreeData?.map((elm, index) => {
                                return(
                                    <div className="detail-row" key={`degreeData00${index}`}>
                                        {elm?.degreeName && elm?.degreeName !== ''
                                            ? <div className="row-div">
                                                <div className="key-text key-text-deg">{i18next.t('DEGREE_CERTI_NAME_LABEL')}:</div>
                                                <div className="value-text">{elm.degreeName}</div>
                                            </div>
                                            : null}

                                        {elm?.completedFrom && elm?.completedFrom !== ''
                                            ? <div className="row-div">
                                                <div className="key-text key-text-deg">{i18next.t('COMPLETED_FROM_LABEL')}:</div>
                                                <div className="value-text">
                                                    {elm.completedFrom}
                                                </div>
                                            </div>
                                            : null}

                                        {elm?.yearOfCompletion && elm?.yearOfCompletion !== ''
                                            ? <div className="row-div">
                                                <div className="key-text key-text-deg">{i18next.t('YEAR_OF_COMPLETION_LABEL')}:</div>
                                                <div className="value-text">
                                                    {elm.yearOfCompletion}
                                                </div>
                                            </div>
                                            : null}

                                        {elm?.pdfPath && elm?.pdfPath !== ''
                                            ? <div className="file-details file-deg">
                                                <div className="file-placeholder" onClick={() => toggle(elm.pdfName, elm.pdfPath)}>{i18next.t('PDF_LABEL')}</div>
                                                <div className="file-info" onClick={() => toggle(elm.pdfName, elm.pdfPath)}>
                                                    <div className="file-name" title={elm?.pdfName}>{elm.pdfName}</div>
                                                    <div className="file-size">{elm.pdfSize} MB</div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                    
                                )
                            })}
                        </div>
                    :   props?.viewType === "about"
                    ?   <div className="about-text">
                            {props?.aboutMeText}
                        </div>
                    :   null}
                </div>
            </div>

            <PDFViewer 
                isOpen={modal}
                onClose={toggle}
                file={fileDetails} /> 
                {/* // {filename: 'Miriam Brooks Resume', filepath: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'}  */}
        </div>
    )
}

export default InfoCard