import { JOB_DATA, HIRING_BOARD_PARAMS } from "../types";

export const saveSelectedJobData = (data) => {
    return (dispatch) => {
      dispatch({ type: JOB_DATA, payload: data });
    };
};

export const saveHiringBoardParamData = (data) => {
  return (dispatch) => {
    dispatch({ type: HIRING_BOARD_PARAMS, payload: data });
  };
};