import React from 'react';
import i18next from 'i18next';

import ForgotPassword from "../../Components/ForgotPassword";
import AppScreen from "../../Components/AppScreen";
import Logo from "../../Components/Logo";
import Card from "../../Components/Card";

function index(props) {
  return (
    <AppScreen>
    <Card>
      <Logo
        title={i18next.t('FORGOT_PASSWORD_T')}
        subtitle={i18next.t('FORGOT_PASSWORD_ST')}
      />
      <ForgotPassword />
    </Card>
  </AppScreen>
  );
}

export default index;